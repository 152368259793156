import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import firebase from 'firebase';
import Geocode from "react-geocode";
import { POSTCODE_REGISTERED, DEVELOPERS, MANAGERS } from '../consts';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU");
Geocode.setLanguage("en");
Geocode.setRegion("en");
Geocode.setLocationType("ROOFTOP");

function EWS1Downgrade(data) {
    const [submitted, setSubmitted] = React.useState(false)
    const [ratings, setRatings] = React.useState(1)
    let postcodeInSystem = false;

    const saveDataToDb = (newNHS) => {
        firebase.firestore().collection("nhs").add(newNHS).then((ref) => { 
            setSubmitted(true);
        });
    }

    const validatePostCode = (postcode) => {
        const regExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
        return regExp.test( postcode );
    }
    
    const handleSubmit = (event) => {

        if (!event.target.postcode.value) {
            alert('Postcode is required.');
            return false;
        }
        if (!validatePostCode(event.target.postcode.value )) {
            alert("Invalid postcode. Please be careful to get it right");
            return false;
        }
        event.preventDefault();
        const newNHS = {
            // role: event.target.role.value,
            finance: event.target.finance.value,
            ability: event.target.ability.value,
            mentalhealth: event.target.mentalhealth.value,
            comments: event.target.comments.value,
            rating: event.target.rating.value,
            helptobuy: event.target.helptobuy.value,
            physical: event.target.physical.value,
            plans: event.target.plans.value,
            service_before: event.target.service_before.value,
            service_after: event.target.service_after.value,
            remediation: event.target.remediation.value,
            // salary: event.target.salary.value,
            shared: event.target.shared.value,
            salary_pc: event.target.salary_pc.value,
            remediation_pc: event.target.remediation_pc.value,
            bankruptcy: event.target.bankruptcy.value,
            verified: false,
            bankruptcy_threat: event.target.bankruptcy_threat.value,
            datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        let cancel = false;
        // Mark as not verified for approval
        newNHS.verified = false;
        Geocode.fromAddress(event.target.postcode.value).then(
            (response) => {
                console.log('response.results ',response.results)
                const { lat, lng } = response.results[0].geometry.location;
                newNHS.lat = lat;
                newNHS.lng = lng;
                saveDataToDb(newNHS);
            },
            (error) => {
                console.error(error);
                newNHS.error = true; 
                saveDataToDb(newNHS);
            }
        );
    }
  const addAnother = () => {
      console.log('addAnother');
      const newRatingsCount = ratings + 1;
      setRatings(newRatingsCount);
  }

  const renderForms = () => {
    const forms = [];
    for (let i = 0; i < ratings; i ++) {
        forms.push(<>
            <fieldset><label htmlFor="rating">EWS1 Grade awarded</label>
                <select name="rating">
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="A3">A3</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                </select>
            </fieldset>
        
            <fieldset><label htmlFor="date">Date of rating <br />(leave blank if unknown or pre-ews1)</label>
                <input id="date" name="date" type="date"></input>
            </fieldset>

            <fieldset><label htmlFor="rating">Qualification of individual signing off</label>
                <select name="qualification">
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="A3">A3</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                </select>
            </fieldset>
            <fieldset><label htmlFor="ews1_comment">Comments/Summary of issue(s)</label>
                <textarea name="ews1_comment"></textarea>
            </fieldset>
        </>)
    }
    return forms;
  }

  return <Container className="sidebar text-left" style={{ paddingTop: '40px', maxWidth: '600px', textAlign: 'left', margin: 0}}>
    <br />
    <h2 >EWS1 Downgrade Form</h2>
    <hr />
    <br />
    <p>This form is to help build a picture of buildings that were given an EWS1 rating, only to have a diffrent awarded at a later date.</p>
    
    <div>
    <br />

    { submitted ?
    <>
        <h1>Thanks!</h1>
        <p>Your contribution is vital to the success of this project.</p>
    </>
    :
    <form className="form" onSubmit={handleSubmit}>
        <fieldset><label htmlFor="postcode">Building Post Code <br />(this is not saved!)</label><input type="text" name="postcode" id="postcode"/></fieldset>        
        <fieldset><label htmlFor="developer">Developer</label><input type="text" name="developer" id="developer"/></fieldset>

        <h3>EWS1 Rating Details<br />(add as many as you need!)</h3>
        <br />
        <div className="ews1-form">
            {renderForms()}
            <fieldset><label></label><Button onClick={addAnother}>+ ADD ANOTHER RATING</Button></fieldset>
        </div>
        <hr />

        <p>DO NOT ENTER PERSONAL DATA INTO THE COMMENTS</p>
        <p>By submitting, you agree to our Privacy policy, and confirm that you are not submitting personal data; postcode is that of the building, and not a person.</p>

        <fieldset><label htmlFor="comments">Comments/Summary of issue(s)</label>
            <textarea name="comments"></textarea>
        </fieldset>
        <fieldset><label></label><Button type="submit">Submit</Button></fieldset>
    </form>
    }
</div>

</Container>
}

export default React.memo(EWS1Downgrade)