import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import firebase from 'firebase';
import Geocode from "react-geocode";
import { POSTCODE_REGISTERED, DEVELOPERS, MANAGERS } from '../consts';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU");
Geocode.setLanguage("en");
Geocode.setRegion("en");
Geocode.setLocationType("ROOFTOP");

function Nhs(data) {
    const [submitted, setSubmitted] = React.useState(false)
    let postcodeInSystem = false;

    const saveDataToDb = (newNHS) => {
        firebase.firestore().collection("nhs").add(newNHS).then((ref) => { 
            setSubmitted(true);
        });
    }

    const validatePostCode = (postcode) => {
        const regExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
        return regExp.test( postcode );
    }

    const cheeckPostcodeExists = (event) => {
        const postcode = event.target.value.toUpperCase();
        if (! validatePostCode(postcode)) {
            return;
        }

        firebase.firestore().collection("sites").where('postcode', '==', postcode)
        .limit(1).get()
            .then((ref) => { 
                postcodeInSystem = !ref.empty
                if (postcodeInSystem) {
                    alert(POSTCODE_REGISTERED)
                }
        });
    }
    
    const handleSubmit = (event) => {

        if (!event.target.postcode.value) {
            alert('Postcode is required.');
            return false;
        }
        if (!validatePostCode(event.target.postcode.value )) {
            alert("Invalid postcode. Please be careful to get it right");
            return false;
        }
        event.preventDefault();
        const newNHS = {
            // role: event.target.role.value,
            finance: event.target.finance.value,
            ability: event.target.ability.value,
            mentalhealth: event.target.mentalhealth.value,
            comments: event.target.comments.value,
            rating: event.target.rating.value,
            helptobuy: event.target.helptobuy.value,
            physical: event.target.physical.value,
            plans: event.target.plans.value,
            service_before: event.target.service_before.value,
            service_after: event.target.service_after.value,
            remediation: event.target.remediation.value,
            // salary: event.target.salary.value,
            shared: event.target.shared.value,
            salary_pc: event.target.salary_pc.value,
            remediation_pc: event.target.remediation_pc.value,
            bankruptcy: event.target.bankruptcy.value,
            verified: false,
            bankruptcy_threat: event.target.bankruptcy_threat.value,
            datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        let cancel = false;
        // Mark as not verified for approval
        newNHS.verified = false;
        Geocode.fromAddress(event.target.postcode.value).then(
            (response) => {
                console.log('response.results ',response.results)
                const { lat, lng } = response.results[0].geometry.location;
                newNHS.lat = lat;
                newNHS.lng = lng;
                saveDataToDb(newNHS);
            },
            (error) => {
                console.error(error);
                newNHS.error = true; 
                saveDataToDb(newNHS);
            }
        );
    }

  return <Container className="sidebar text-left" style={{ paddingTop: '40px', maxWidth: '800px', textAlign: 'left', margin: 0}}>




    <br />
    <h2 >🌈 NHS WORKERS</h2>
    <hr />
    <br />
    <p>Thank you for your hard work, especially in the last year re. COVID.</p>
    <p>    We want to find out how many of the buildings submitted impact NHS workers and to understand the impact these situations are having on your life.</p>
    <p>
    We believe it is unacceptable that our NHS heroes should suffer persecution via the cladding scandal: it is vital that our NHS staff are protected.  We believe that this Map project can help to ask, compile and present this the data to help visualise the gravity of the situation.
    </p>
    <p>We do not ask for personal data and we do not save your post code.<br />
    We will use the approximated location to create an overlay on the map which shows the scale of the impact of this scandal on those NHS workers who've been sacrificing so much over the last 12+ months; see <a href="/privacy">Privacy policy</a> &amp; <a href="/faq">FAQ</a> for more info.</p>
    <p><strong>This form is for NHS workers living in cladding scandal affected housing only.</strong><br /> 
    We empathise, recognise and celebrate the efforts of all other key workers during the last year but have limited the study for present purposes to create the most direct visual impact</p>
    <br />

<div>
    <br />

    { submitted ?
    <>
        <h1>Thanks!</h1>
        <p>Your contribution is vital to the success of this project.</p>
    </>
    :
    <form className="form" onSubmit={handleSubmit}>

        <fieldset><label htmlFor="postcode">Building Post Code <br />(this is not saved!)</label><input onChange={cheeckPostcodeExists} type="text" name="postcode" id="postcode"/></fieldset>        
        <fieldset><label htmlFor="rating">EWS1 Grade awarded</label>
            <select name="rating">
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="A3">A3</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
            <option value="unnknown">unknown</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="helptobuy">Did your purchase through help to buy?</label>
            <select name="helptobuy">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="shared">Did your purchase through shared ownership?</label>
            <select name="shared">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>

        {/* <fieldset><label htmlFor="role">NHS Job Type</label>
            <select name="role">
                <option value="nurse">Nurse</option>
                <option value="doctor">Doctor</option>
                <option value="social">Health and Social Care</option>
                <option value="emergency">Emergency Services</option>
                <option value="allied">Allied Health Care Professional</option>
                <option value="admin">Admin &amp; clerical</option>
                <option value="Managerial">Managerial</option>
                <option value="prefernottosay">Prefer not to say</option>
                <option value="other">Other</option>
            </select>
        </fieldset> */}

        <fieldset><label htmlFor="mentalhealth">Has the cladding issue affected your MENTAL health negatively?</label>
            <select name="mentalhealth">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="physical">Has the cladding issue affected your PHYSICAL health negatively?</label>
            <select name="physical">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="finance">Has the cladding issue affected your FINANCES negatively?</label>
            <select name="finance">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="ability">Has the cladding issue affected your ability to do your job as a key worker negatively?</label>
            <select name="ability">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="plans">Has the cladding issue affected your life plans I.e. moving home, starting/growing a family?</label>
            <select name="plans">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>


        {/* <fieldset><label htmlFor="salary">Please enter your salary band <br />This is ANNONYMOUS and we will use it to speculate impact of waking watch and other costs.</label>

        <select name="salary">
            <option value="1">Band 1</option>
            <option value="2">Band 2</option>
            <option value="3">Band 3</option>
            <option value="4">Band 4</option>
            <option value="5">Band 5</option>
            <option value="6">Band 6</option>
            <option value="7">Band 7</option>
            <option value="8">Band 8</option>
            <option value="9">Band 9</option>
            </select>
        </fieldset> */}

        <fieldset><label htmlFor="service_before">Your household service charge BEFORE EWS1</label>
            <input type="number" name="service_before" id="salary" />
        </fieldset>

        <fieldset><label htmlFor="service_after">Your household service charge AFTER EWS1<br />(leave blank if you don't know (yet)) </label>
            <input type="number" name="service_after" id="salary" />
        </fieldset>

        <fieldset><label htmlFor="salary_pc">
            Roughly what % of your TAKE HOME PAY is spent on extra service charge due to cladding scandal issues?</label>
        <select name="salary_pc">
            <option value="na">N/A</option>
            <option value="dontknow">I don't know (yet)</option>
            <option value="0">0 %</option>
            <option value="10">10%</option>
            <option value="20">20%</option>
            <option value="30">30%</option>
            <option value="40">40%</option>
            <option value="50">50%</option>
            <option value="60">60%</option>
            <option value="70">70%</option>
            <option value="80">80%</option>
            <option value="90">90%</option>
            <option value="100">100%</option>
            <option value="100+">More than 100%</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="remediation">Your household's Alarm/remediation bill <br />(leave blank if you don't know (yet)) </label>
            <input type="number" name="remediation" id="salary" />
        </fieldset>

        <fieldset><label htmlFor="remediation_pc">
            Roughly what % of your ANNUAL SALARY is the remediation costs? <br/> Note: 200% = 2 times, 300% = 3 times etc.</label>
        <select name="remediation_pc">
        <option value="na">N/A</option>
            <option value="dontknow">I don't know (yet)</option>
            <option value="0">0 %</option>
            <option value="20">25%</option>
            <option value="50">50%</option>
            <option value="70">75%</option>
            <option value="100">100%</option>
            <option value="150">150%</option>
            <option value="200">200%</option>
            <option value="250">250%</option>
            <option value="300">300%</option>
            <option value="350">350%</option>
            <option value="400">400%</option>
            <option value="450">450%</option>
            <option value="500">500%</option>
            <option value="500+">More than 500%</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="bankruptcy">Are you facing bankruptcy as a direct result of the cladding scandal?</label>
            <select name="bankruptcy">
            <option value="dontknow">I don't know (yet)</option>
            <option value="no">No</option>
            <option value="yes">Yes</option>
            
            </select>
        </fieldset>

        <fieldset><label htmlFor="bankruptcy_threat">Would personal bankruptcy disqualify you from your current profession?</label>
            <select name="bankruptcy_threat">
                
            <option value="no">No</option>
            <option value="yes">Yes</option>
            </select>
        </fieldset>


        

        <hr />

        <p>DO NOT ENTER PERSONAL DATA INTO THE COMMENTS</p>
        <p>By submitting, you agree to our Privacy policy, and confirm that you are not submitting personal data; postcode is that of the building, and not a person.</p>

        <fieldset><label htmlFor="comments">Comments/Summary of issue(s)</label>
            <textarea name="comments">            
            </textarea>
        </fieldset>
        <fieldset><label></label><Button type="submit">Submit</Button></fieldset>
    </form>
    }
</div>

</Container>
}

export default React.memo(Nhs)