import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import firebase from 'firebase';
import Stats from './Stats';
import Share from './Share';
import Geocode from "react-geocode";
import { POSTCODE_REGISTERED, REMEDIATIONS } from '../consts';
import { NhsLogo } from './Logo';
import { LOCALES } from '../Locales';
import { useAppState } from '../Store';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU");
Geocode.setLanguage("en");
Geocode.setRegion("en");
Geocode.setLocationType("ROOFTOP");

const ALLOWED_ERROR_POSTCODES = ['CB2 8EN'];

function SideBar(data) {
    const [submitted, setSubmitted] = React.useState(false)
    const {state} = useAppState();
    const lang = state.lang ?? 'en';
    const locale = LOCALES[lang];
    const updated = data.data.updated ? new Date(data.data.updated.seconds * 1000).toLocaleString("en-GB") : '';

    let postcodeInSystem = false;

    const saveDataToDb = (newSite) => {
        firebase.firestore().collection("sites").add(newSite).then((ref) => { 
            setSubmitted(true);
        });
    }

    const validatePostCode = (postcode) => {
        if (ALLOWED_ERROR_POSTCODES.includes(postcode)) {return true;}
        const regExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
        return regExp.test( postcode );
    }

    const cheeckPostcodeExists = (event) => {
        const postcode = event.target.value.toUpperCase();
        if (! validatePostCode(postcode)) {
            return;
        }

        firebase.firestore().collection("sites").where('postcode', '==', postcode)
        .limit(1).get()
            .then((ref) => { 
                postcodeInSystem = !ref.empty
                if (postcodeInSystem) {
                    alert(POSTCODE_REGISTERED)
                }
        });
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log('event',event)

        if (!event.target.postcode.value) {
            alert('Postcode is required.');
            return false;
        }
        if (!validatePostCode(event.target.postcode.value )) {
            alert("Invalid postcode. Please be careful to get it right");
            return false;
        }

        const newSite = {
            developer: event.target.developer.value,
            management: event.target.management.value,
            units: parseInt(event.target.units.value),
            rating: event.target.rating.value,
            date: event.target.date.value,
            age: event.target.age.value,
            height: event.target.height.value,
            wakingwatch: event.target.wakingwatch.value,
            ra: event.target.ra.value,
            comments: event.target.comments.value,
            costspassedtoleaseholder: event.target.costspassedtoleaseholder.value,
            funds: event.target.funds.value,
            datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        };

        REMEDIATIONS.forEach((item, i) => {
            const key = 'remediation_'+item.trim().replaceAll(' ','_').toLowerCase();
            const checked = event.target[key].checked;
            if (!checked) { return; }
            newSite[key] = true;
        });

        if (!newSite.developer) {
            alert('Developer is required.');
            return false;
        }
        if (!newSite.height) {
            alert('Height is required.');
            return false;
        }
        if (!newSite.units || !event.target.units.value) {
            alert('Units is required.');
            return false;
        }
        if (!newSite.management) {
            alert('Management is required.');
            return false;
        }

        // Mark as not verified for approval
        newSite.verified = false;

        // All good, get the lang lat
        Geocode.fromAddress(event.target.postcode.value).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                newSite.lat = lat;
                newSite.lng = lng;
                saveDataToDb(newSite);
            },
            (error) => {
                console.error(error);
                newSite.error = true; 
                saveDataToDb(newSite);
            }
        );
    }

    const renderMobileStatsPanel = () => window.innerWidth < 768 ? null : <div className="content-block xs-hide"><Stats data={data} /></div>;
    
  return <Container className="sidebar text-left">
    <Row>
    <h2>{locale.sideBar.title}</h2>
    <p>{locale.sideBar.body} {locale.sideBar.updated}: { updated }.</p>
    <p>For updates, articles, discussions, and event information, see our partner site at <a href="http://buildingsafetycrisis.org/" title="See articles on the cladding scandal at the Building Safety Crisis website">buildingsafetycrisis.org</a> </p>

    {/* <p><a href="https://firebasestorage.googleapis.com/v0/b/claddingscandalmap.appspot.com/o/assets%2FA0-map.png?alt=media&token=c31c5da5-e5cc-4e57-a27d-5e89f794184e">Download our map as an A0 image</a> </p> */}
    
    { renderMobileStatsPanel() }

    <div className="content-block">

    <a id="report"></a>
    <h2>{locale.form.title}</h2>
    <p>{locale.form.body}</p>
        <p><strong>Worried about duplicates?</strong></p>
    <p>Our team checks any new submissions for duplicate entries so if you're not sure whether you building has been uploaded already, please submit and we will sort it out!</p>
    <br />
    {/* <p>Add your remediation bill to our database - <a href="/bills">EWS1 Bill Form</a></p> */}
    {/* <p>Fill out the form and we'll add you to the map after verification. Please complete the form in as much detail as possible. And please check the map for your site first. </p>
    <p>This project is entirely crowdsourced; you accept that you are responsible for the data you upload. DO NOT ENTER PERSONAL DETAILS.</p>
    <br /> */}

    { submitted ?
    <>
        <h1>Thanks!</h1>
        <p>Your contribution is vital to the success of this project. Please check back soon and your site will be on the map.</p>
        <p>Don't forget to share this page with others!</p>
        <Share />
    </>
    :
    <form onSubmit={handleSubmit}>
        <fieldset><label htmlFor="developer">Developer</label><input type="text" name="developer" id="developer"/></fieldset>
        <fieldset><label htmlFor="management">Managing Body</label><input type="text" name="management" id="management"/></fieldset>
        <fieldset><label htmlFor="postcode">Building Post Code (this is not saved!)</label><input onChange={cheeckPostcodeExists} type="text" name="postcode" id="postcode"/></fieldset>
        <fieldset><label htmlFor="units">Total Units (flats)</label><input type="text" name="units" id="units"/></fieldset>
        <fieldset><label htmlFor="rating">EWS1 Grade awarded</label>
            <select name="rating">
                <option value="pre">PRE EWS1</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="A3">A3</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
            <option value="unnknown">unknown</option>
            </select>
        </fieldset>
        <fieldset><label htmlFor="date">Date of rating <br />(leave blank if unknown or pre-ews1)</label>
            <input id="date" name="date" type="date"></input>
        </fieldset>

        <fieldset><label htmlFor="age">Building age</label>
            <select name="age">
            <option value="newbuild">Newbuild</option>
            <option value="10">5-10 years</option>
            <option value="20">10-20 years</option>
            <option value="21">20 years +</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="height">Building height</label>
            <select name="height">
            <option value="under-11">Under 11M</option>
            <option value="11-18">11M-18M</option>
            <option value="18+">18M+</option>
            </select>
        </fieldset>

        <fieldset><label htmlFor="wakingwatch">Waking Watch?</label>
            <select name="wakingwatch">
                <option value="yes">No</option>
                <option value="no">Yes</option>
                <option value="dontknow">I Don't know</option>
            </select>
        </fieldset>
        <fieldset><label htmlFor="costspassedtoleaseholder">Have/will costs be passed on to leaseholder?</label>
            <select name="costspassedtoleaseholder">
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="dontknow">I don't know</option>
            </select>
        </fieldset>
        <fieldset><label htmlFor="funds">Have/will funds be awarded?</label>
            <select name="funds">
            <option value="yes">Yes - from developer</option>
            <option value="yes">Yes - from govt</option>
                <option value="no">No</option>
                <option value="dontknow">I don't know</option>
            </select>
        </fieldset>
        <fieldset><label htmlFor="ra">Part of a Resident's Association?</label>
            <select name="ra">
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="dontknow">I don't know</option>
            </select>
        </fieldset>

        <fieldset className="checkboxes">
            <p>Remediation required on (tick all that apply):</p>
            { REMEDIATIONS.map((item, i) => {
                const key = item.trim().replaceAll(' ','_').toLowerCase();
                return <div><input type="checkbox" name={"remediation_"+key} value="yes" id={"remediation_"+key} />
                    <label for={"remediation_"+key}>{item}</label></div>
            })}
        </fieldset>


        <hr />
        <p>DO NOT ENTER PERSONAL DATA INTO THE COMMENTS</p>
        <p>By submitting, you agree to our <a href="/privacy">Privacy policy</a>, and confirm that you are not submitting personal data; postcode is that of the building, and not a person; you agree that you are authorised to do so.</p>

        <fieldset><label htmlFor="comments">Comments/Summary of issue(s)</label>
            <textarea name="comments">            
            </textarea>
        </fieldset>
        <fieldset><label></label><Button type="submit">Submit</Button></fieldset>
    </form>
    }
</div>

    </Row>
</Container>
}

export default React.memo(SideBar)