import React, { useReducer, withStore, createContext, useContext } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const initialState = { lang: 'en' };
const store = createContext(initialState);

const { Provider } = store;

export const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        const currentState = { ...state };
        switch (action.type) {
            case 'SET_LANGUAGE':
                currentState.lang = action.payload;
                return currentState;
            default:
                throw new Error();
        }

    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export const useAppState = () => {
    const context = useContext(store);
    if (!context) {
      throw new Error("useAppState must be used within the AppStateProvider");
    }
    return context;
  }
  