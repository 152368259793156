import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faUserTie, faUsers, faBed, faPoundSign, faHouseUser, faTools, faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import { LOCALES } from '../Locales';
import { LineChart, ColumnChart } from 'react-chartkick'
import 'chartkick/chart.js'
import { useAppState } from '../Store';

const COLORS = {
    'A1': '#ffc107',
    'A2': '#3A7D44',
    'A3': '#CD5334',
    'B1': '#51E5FF',
    'B2': '#dc3545',
    'unnknown': '#6c757d',
};

const COLORS_HEIGHTS = {
    'Under 11M': '#CD5334',
    '11-18M': '#ffc107',
    '18M+': '#51E5FF',
};

const COLORS_AGES = {
    'Newbuild': '#ffc107',
    '5-10': '#3A7D44',
    '10-20': '#CD5334',
    '20+': '#51E5FF',
};

const MEDIAN_WAKING_WATCH_CPM = 11361;

const nFormatter = (num, digits) => {
    const si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
        break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }


function Stats(data) {
    const {state} = useAppState();
    const lang = state.lang ?? 'en';
    const locale = LOCALES[lang];
    const totalBuildings = data.data.data.nodes.length;
    const totalUnits = data.data.data.units;
    const wakingWatchPercent = data.data.data.wakingwatch;
    const raPercent = data.data.data.residentsAssociation;
    const wwAverage = nFormatter(MEDIAN_WAKING_WATCH_CPM * ( totalBuildings / 100 * wakingWatchPercent), 2);
    const remediations = data.data.data.remediations ?? [];
    const eligibilityPercent = data.data.data.eligibilityPercent;
    const heights = data.data.data.heights ?? {};

    const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const generatePieChart = () => {
        const A1 = data.data.filter((item)=>item.rating === 'A1').length;
        const A2 = data.data.filter((item)=>item.rating === 'A2').length;
        const A3 = data.data.filter((item)=>item.rating === 'A3').length;
        const B1 = data.data.filter((item)=>item.rating === 'B1').length;
        const B2 = data.data.filter((item)=>item.rating === 'B2').length;
        const unknown = data.data.filter((item)=>item.rating === 'unnknown').length;
        return [
            { title: 'A1: '+ A1, value: A1, color: '#FAD8D6' },
            { title: 'A2: '+ A2, value: A2, color: '#4C443C' },
            { title: 'A3: '+ A3, value: A3, color: '#CD5334' },
            { title: 'B1: '+ B1, value: B1, color: '#EDB88B' },
            { title: 'B2: '+ B2, value: B2, color: '#2E282A' },
            { title: 'Unknown: '+ unknown, value: unknown, color: '#17BEBB' },
        ]
    }
  
  return <Container style={{ fontWeight: '600'}}>
    <Row>
        <Col className="icon-wrap">
            <FontAwesomeIcon className="icon-large" icon={faCity} />
            <p>{locale.headers.buildings}: <strong>{totalBuildings}</strong></p>
        </Col>
        <Col className="icon-wrap main">
            <FontAwesomeIcon className="icon-large" icon={faUsers} />
            <p>{locale.headers.unitsAffected}:  <strong>~{ numberWithCommas(totalUnits ?? '')}</strong></p>
        </Col>
    </Row>
    <Row>
        <Col className="icon-wrap">
            <FontAwesomeIcon className="icon-large" icon={faBed} />
            <p>{locale.headers.wakingWatch}: <strong>{wakingWatchPercent}%</strong></p>
        </Col>
        <Col className="icon-wrap">
            <FontAwesomeIcon className="icon-large" icon={faPoundSign} />
            <p title="Waking watch median cost of £11,361/month taken from gov.uk">
            {locale.headers.wakingWatch}<br />{locale.headers.totalMonth} 
            <span className="font-small"> [1]</span>
            {/* Waking Watch<br /> est. total/month <span className="font-small">[1]</span>:  */}
            <strong>£{wwAverage}</strong></p>
        </Col>
    </Row>

    <Row>
    {/* <Col className="icon-wrap">
            <FontAwesomeIcon className="icon-large" icon={faUserTie} />
            <p title="Exact calculation coming soon. There are currently 50+!">{locale.headers.developers}:  <strong>50+</strong></p>
        </Col> */}
        <Col className="icon-wrap">
            <FontAwesomeIcon className="icon-large" icon={faCheckSquare} />
    <p>Eligible for FULL <br />BSF funding<span className="font-small"> [2]</span>:  <strong>{eligibilityPercent}%</strong></p>
        </Col>
        <Col className="icon-wrap">
            <FontAwesomeIcon className="icon-large" icon={faHouseUser} />
            <p>{locale.headers.raFormed}: <strong>{raPercent}%</strong></p>
        </Col>
    </Row> 

    <Row>
        <Col className="icon-wrap" style={{ position: 'relative'}}>
            <h2 style={{ textAlign: 'left'}}>{locale.headers.top5Remediation}</h2>
            <ol style={{ textAlign: 'left', marginTop: '16px', textTransform: 'capitalize'}}>
                { remediations.map((item) => <li style={{marginBottom: '4px'}}>{item}</li>) }
            </ol>
            <div style={{ position: 'absolute', fontSize: '40px', float: 'right', top: '56px', right: '0px'}}>
                <FontAwesomeIcon  className="icon-mega" icon={faTools} />
            </div>
        </Col>
    </Row> 

    <Row>
        <Col className="icon-wrap" style={{ width:' 100px'}}>
            <p>{locale.headers.ratingsPerMonth}</p>
            <LineChart height={'220px'} data={ data.data.data.dates } xtitle="Month" ytitle="EWS1 Awarded (Whole Month)" colors={['white', 'green']}/>
        </Col>
    </Row>

    <Row>
        <Col className="icon-wrap">
            <Row>
                <Col><div style={{ height: '150px', paddingLeft: '16px'}}><p style={{ marginTop: '4px', marginBottom: '20px'}}>{locale.headers.ratingType}</p>
                    <PieChart
                        data={
                            Object.keys(data.data.data.ratings ?? []).map((key)=> ({ title: key, value: data.data.data.ratings[key], color: COLORS[key] }))
                        }/>
                    </div>
                </Col>
                <Col>
                    <ul style={{ listStyle: 'none', marginTop: '16px', paddingLeft: '24px'}} className="text-left">
                        { Object.keys(COLORS).map((key)=>
                            <li style={{ listStyle: 'none', margin: '4px 0px'}}>
                            <span style={{ display: 'inline-block', verticalAlign: 'text-bottom', height: '16px', width: '16px', background: COLORS[key], marginRight: '4px'}}></span> {key === 'unnknown' ? 'Unknown' : key }
                        </li>)
                        }
                    </ul>
                </Col>
            </Row>
        </Col>
    </Row>

    <Row>
        <Col className="icon-wrap">
            <Row> 
            <Col>
            <p style={{ marginTop: '16px', marginBottom: '24px'}}>{locale.headers.buildingHeight}</p>
            <div style={{margin: 'auto'}}>
                <ColumnChart opacity={false} width={"240px"} height={ "240px"} data={ 
                    { 
                        'Under 11M': heights['under-11'],
                        '11-18M': heights['11-18'],
                        '18M+': heights['18+'],
                    }
                 } colors={Object.keys(COLORS_HEIGHTS).map((key)=>COLORS_HEIGHTS[key])} />
                 </div>
            </Col>
            {/* <Col>
            <p style={{ marginTop: '16px', marginBottom: '24px'}}>
            {locale.headers.buildingHeight}</p>
                <ul style={{ listStyle: 'none', marginTop: '16px', paddingLeft: '16px'}} className="text-left">
                    { Object.keys(COLORS_HEIGHTS).map((key)=>
                        <li style={{ listStyle: 'none', margin: '4px 0px'}}>
                        <span style={{ display: 'inline-block', verticalAlign: 'text-bottom', height: '16px', width: '16px', background: COLORS_HEIGHTS[key], marginRight: '4px'}}></span> {key === 'unnknown' ? 'Unknown' : key }
                    </li>)
                    }
                </ul>
            </Col> */}
            </Row>
        </Col>
    </Row>

    <Row>
        <Col className="icon-wrap" style={{ minHeight: '208px'}}>
            <Row>
                <Col>
                    <p style={{ marginLeft: '22px', textAlign: 'left', marginTop: '4px', marginBottom: '20px'}}>Building Age (yrs)</p>
                    <ul style={{ listStyle: 'none', marginTop: '16px', paddingLeft: '24px'}} className="text-left">
                        { Object.keys(COLORS_AGES).map((key)=>
                            <li style={{ listStyle: 'none', margin: '4px 0px'}}>
                            <span style={{ display: 'inline-block', verticalAlign: 'text-bottom', height: '16px', width: '16px', background: COLORS_AGES[key], marginRight: '4px'}}></span> {key === 'unnknown' ? 'Unknown' : key }
                        </li>)
                        }
                    </ul>
                </Col>

                <Col><div style={{ height: '150px', paddingLeft: '16px', marginTop: '16px'}}>
                    <PieChart
                        data={
                            Object.keys(data.data.data.ages ?? {}).map((key)=> ({ title: key, value: data.data.data.ages[key], color: COLORS_AGES[key] }))
                        }/>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>

    <p style={{ fontSize: '10px', marginTop: '8px'}}>
        All stats derived from unvetted, unofficial user-submitted data for illustrative purposes only. 
        We take no responsibility for this data, nor its accuracy.<br />
        [1] Speculative estimate of total cost for waking watch for all buildings that reported it; median cost of £11,361/month taken from gov.uk on 14.4.21.<br />
        Formula: {MEDIAN_WAKING_WATCH_CPM} * ({totalBuildings} / 100 * {wakingWatchPercent})<br />
        [2] % of buildings who's reported issues are eligible for full BSF funding, ie building 18m+ and cladding only.<br />
    </p>
</Container>
}

export default React.memo(Stats)