const en = {
    buttons: {
        add: 'Add a building',
        share: 'Share',
    },
    headers: {
        buildings: 'Buildings',
        unitsAffected: 'Homes affected',
        wakingWatch: 'Waking Watch',
        totalMonth: 'est. total/month',
        developers: 'Developers',
        raFormed: "Resident's Association Formed",
        ratingsPerMonth: 'Ratings Per Month',
        ratingType: 'Rating Type',
        buildingHeight: 'Building Height Grouping',
        top5Remediation: 'Top 10 remediations',
    },
    sideBar: {
        title: 'Welcome!',
        body: `Welcome to the Building Safety Map - the Official Citizen Science Project to map the Building Safety Crisis. We use crowd-sourced data to illustrate the scale of the current Building Safety Crisis that is gripping the UK.`,
        updated: 'Updated',
    },
    body: {
        int: {
            title: 'INTERNATIONAL INVESTORS',
            body: `We are aware that this situation has very little if no coverage globally. Meanwhile, developers are still marketing their properties at conventions in countries such as China, Russia, the UAE, and more. This is unacceptable and we want to apologise on behalf of the UK - our public welcomes you.`,
        },
        who: {
            title: 'WHO ARE WE?',
            body: 'A community of people (lease holders) affected by the Building Safety Crisis. In our day jobs, we are data experts - ranging from tech to cyber security. We took the task of collecting and sharing data upon ourselves as a professional duty to every affected leaseholder.'
        },
        what: {
            title: 'WHAT IS THE BUILDING SAFETY CRISIS (AND WHO IS AFFECTED)?',
            body: `The Grenfell Tower disaster in June 2017, which claimed 72 lives, triggered calls for changes to UK building regulations and the strengthening of fire safety measures.
                The fires revealed that large numbers of buildings had been clad in dangerously combustible materials. Additionally, many buildings have been found to be non-compliant with other fire-safety building requirements which are intended to prevent fires from spreading horizontally and vertically into neighbouring flats.
                As well as these buildings posing an immediate fire risk to residents, flat owners find themselves facing extensive and costly remedial work, rocketing buildings insurance premiums, and the possibility of 'waking watches': paying people to patrol buildings 24/7 to check for fires.
                Mortgage lenders, struggling to quantify the fire-risks posed by different buildings, have ceased to lend money for the purchase of many properties unless their owners can prove the building is safe, usually by way of an 'EWS1' form.
                As of February 2021, the UK government had pledged over £5 billion towards remediation works, but extensive costs were still falling on the shoulders of individual leaseholders of flats who had unwittingly purchased unlawfully constructed homes.
                Our aim is to connect all those affected by the ongoing cladding issue, and to generate visibility. Zoom is locked to a certain height, and no site data (postcode, title, etc) is used. We do not take personal data, just a summary for sites affected. We intend on using this data to apply pressure and improve the rights of the leaseholder in doing so.
                Find out more on our FAQ`
        },
        press: {
            title: 'PRESS & COMMS',
            body: `We would love to talk to you and share what's going on. Email us at: claddingscandalmap@gmail.com`,
        }
    },
    form: {
        title: 'ADD A BUILDING',
        body: `Fill out the form and we'll add you to the map after verification. Please complete the form in as much detail as possible. 
            This project is entirely crowdsourced; you accept that you are responsible for the data you upload. DO NOT ENTER PERSONAL DETAILS.`
    }
}

const ru = {
    buttons: {
        add: 'Добавить здание',
        share: 'Поделиться',
        updated: 'Updated',
    },
    headers: {
        buildings: 'Здания',
        unitsAffected: 'Количество затронутых квартир',
        wakingWatch: ' Наличие пожарной вахты',
        totalMonth: 'Стоимость в месяц [1]',
        developers: 'Застройщики',
        raFormed: "Количество АР (ассоциаций резидентов, аналог ТСЖ)",
        ratingsPerMonth: 'Количество выданных рейтингов EWS1 в месяц',
        ratingType: 'Рейтинг',
        buildingHeight: 'Высота здания',
        top5Remediation: 'Top 10 Remediations',
    },
    sideBar: {
        title: 'ОТМЕТЬТЕ СЕБЯ НА КАРТЕ!',
        body: `Добро пожаловать на наш сайт о проблеме пожарной безопасности в Великобритании. Сайт создан сообществом владельцев квартир, втянутых в облицовочный скандал и вынужденных бороться за свои права. Мы используем краудсорсинг данные, чтобы проиллюстрировать масштаб проблемы. Проект был запущен 8 апреля 2021 года. Проект новый, и мы с благодарностью примем вашу поддержку - обозначайте свои здания на карте!`
    },
    body: {
        int: {
            title: 'МЕЖДУНАРОДНЫЕ ПОКУПАТЕЛИ',
            body: `Мы знаем, что эта ситуация почти не имеет глобального освещения. Тем временем строительные компании и девелоперы рекламируют и продают построенные ими квартиры на строительных форумах и конференциях в таких странах как Китай, Россия, ОАЭ и др. На наш взгляд это возмутительно и недопустимо, от лица жителей Великобритании мы хотим принести наши извинения.            `,
        },
        who: {
            title: 'КТО МЫ ТАКИЕ?',
            body: `Мы - сообщество квартирных владельцев, пострадавших от облицовочного скандала. Мы не работаем в строительной индустрии и не связаны с политикой. Среди нас медсестры, журналисты, айти инженеры, менеджеры, продавцы - обычные люди, купившие квартиры и теперь находящиеся на грани банкротства.`
        },
        what: {
            title: 'ЧТО ТАКОЕ ОБЛИЦОВОЧНЫЙ СКАНДАЛ И КТО ОТ НЕГО ПОСТРАДАЛ?',
            body: `Трагический пожар в Гренфелле в июне 2017 года, унесший жизни 72 человек, был отправной точкой для изменения британских строительных стандартов и ужесточения мер пожарной безопасности.
            Эта трагедия показала, что большое количество зданий облицовано опасными горючими материалами. Кроме того, многие здания были признаны несоответствующими другим требованиям пожарной безопасности, которые направлены на предотвращение горизонтального и вертикального распространения пожаров на соседствующие квартиры.
            Наряду с тем, что здания представляют непосредственную опасность для жителей, владельцы квартир сталкиваются с обширными и дорогостоящими ремонтными работами, с непомерно высоко взлетевшими страховыми взносами, а также с необходимостью введения "пожарной вахты": патрулирования зданий 24 часа в сутки 7 дней в неделю для проверки на наличие пожара.
            Ипотечные кредиторы и банки, пытаясь количественно оценить пожарные риски, создаваемые различными зданиями, перестали давать кредиты на покупку многих объектов недвижимости, если только их владельцы не смогут доказать, что здание является безопасным, обычно в форме сертификата EWS1 не ниже B1.
            По состоянию на февраль 2021 года, правительство Великобритании пообещало более 5 миллиардов фунтов стерлингов на восстановительные работы, но обширные затраты все еще падают на плечи индивидуальных арендаторов квартир, которые невольно приобрели дома, не соответствующие нормам пожарной безопасности.
            Наша цель - объединить всех жертв облицовочного и пожарного скандала, визуализируя проблему. Масштаб карты фиксируется на определенной высоте, при этом не используются данные сайта (почтовый индекс, название и т.д.). Мы не принимаем персональные данные, и выводим только сводку по пострадавшим зданиям. Мы намерены использовать эти данные для оказания давления на индустрию и для улучшения прав квартировладельцев.
            Ответы на часто задаваемые вопросы`
        },
        press: {
            title: 'ПРЕССА И КОНТАКТЫ',
            body: `Мы приветствуем новые дружественные контакты. Напишите нам на нашу электронную почту: claddingscandalmap@gmail.com.`,
        }
    },
    form: {
        title: 'ДОБАВИТЬ ЗДАНИЕ',
        body: `Заполните форму и после проверки данных мы добавим ваше здание на карту. Пожалуйста, внесите как можно больше деталей.
            Этот проект создан на основе краудсорсинга; вы принимаете ответственность за вводимые вами данные. НЕ ВВОДИТЕ СВОИ ЛИЧНЫЕ ДАННЫЕ.   
        `
    }
}

const zh = {
    buttons: {
        add: '新增建筑物',
        share: '分享',
        updated: 'Updated',
    },
    headers: {
        buildings: '小区',
        unitsAffected: '受影响的公寓',
        wakingWatch: '防火警戒巡逻',
        totalMonth: '警戒巡逻费用/月[1]',
        developers: '地产商',
        raFormed: "业主协会比例",
        ratingsPerMonth: '每月证书发放数量',
        ratingType: '证书等级分类',
        buildingHeight: '建筑楼高分类',
        top5Remediation: '10常见问题',
    },
    sideBar: {
        title: '让我们一起在丑闻地图标记出来',
        body: `欢迎加入英国居民建筑易燃外墙丑闻地图。 我们是由受此丑闻影响的业主们志愿组成的社区。 我们将通过大数据和地图来向公众呈现该丑闻的规模和影响。 此地图于2021年4月8日正式上线并且被不断更新。让我们一起将受影响的公寓标记出来！`,
    },
    body: {
        int: {
            title: '国际投资者',
            body: `由于英国境外的媒体并没有对此丑闻进行系统的报道，这使得无所顾忌的英国开发商们仍然在中国，新加坡，俄罗斯，阿联酋等国家营销这些在英国已经一文不值的公寓。这是对投资者的欺骗。英国的公众对这此丑闻深表歉意`,
        },
        who: {
            title: '我们是谁?',
            body: '我们是一群受居民建筑易燃外墙丑闻影响的居民业主：我们无开放商或任何政治背景。'
        },
        what: {
            title: '居民建筑易燃外墙丑闻是什么? (受影响的都有谁?)',
            body: `2017年6月的一场位于伦敦格伦菲尔塔(Grenfell)的世纪大火带走了72条宝贵的生命。这是英国历史上最严重的居民楼火灾。这场大火引发了英国民众对修改建筑法规，加强消防安全措施的强烈呼吁。
            火灾之后的调查表明，格伦菲尔塔建筑的外墙采用了极其易燃的建材，导致了火势迅速蔓延。后续问责发现，英国许多的高层公寓住宅都含有易燃的建材，火灾隐患极大。这些不符合新修订的消防安全法的住宅被要求采取整修工作已拆除现有的易燃建材，然而昂贵的补救费用往往由业主承担 （每家的整修费用可以达到几十万英镑）。建筑保险费以及整修期间的火警巡逻费（请人全天候警戒巡逻建筑以预防火灾的发生 ）也常常由业主承担。 
            银行和同类贷款机构在考虑提供房屋按揭贷款前，必须要求业主出示该建筑的外墙火灾审查证（EWS1），任何不符合规定的公寓都无法获得银行的贷款。
            英国政府已承诺投入50亿英镑用于整修工程，但行内预估修缮所有英国不合格的高层居民楼需要大改五百亿英镑，所以英国政府的拨款不过是沧海一粟。作为普通民众，合法买的公寓一夕之间分文不值，业主在无良的开发商，物业公司和政府的博弈之间面对重重困境。
            我们的宗旨是联合所有受此丑闻影响的业主，借集体之力发声维权。江海不辞细流，故能成其深；每个人的力量虽然微弱，但联合起来我们可以游说英国政府，为业主讨伐正义。 
            为遵守个人隐私法，我们的地图将无法无限放大，不使用站点数据（比如邮政编码，小区名称等）， 不收集个人数据。 我们只收集受影响的公寓小区摘要。 我们的目的是利用收集的大数据向政府施压并维护业主权益。
            更多咨询可以参考常见问题FAQ。
            新闻媒体&通讯 PRESS & COMMS
            我们很乐意与您交谈并分享最新动态。 请发电子邮件至：claddingscandalmap@gmail.com.            
            `
        },
        press: {
            title: '新闻媒体&通讯',
            body: `我们很乐意与您交谈并分享最新动态。 请发电子邮件至：claddingscandalmap@gmail.com`,
        }
    },
    form: {
        title: '添加公寓信息',
        body: `请填写以下表格，我们将在验证后将您添加到地图。 请尽可能详细地填写表格
        这个项目完全是集合大众的知识力量。请对个人上传的数据负责认真填写。不要输入个人相关详细信息！

        `
    }
}

const es = {
    buttons: {
        add: 'Añadir un edificio',
        share: 'Compartir',
        updated: 'Updated',
    },
    headers: {
        buildings: 'Edificios',
        unitsAffected: 'Unidades afectadas',
        wakingWatch: 'Vigilantes de incendios',
        totalMonth: 'Estimación total/mes [1]',
        developers: 'Promotores inmobiliarios',
        raFormed: "Comunidad de propietarios formada",
        ratingsPerMonth: 'Calificaciones mensuales',
        ratingType: 'Tipo de calificación',
        buildingHeight: 'Grupo de edificios según altura',
        top5Remediation: '10 problemas comunes'
    },
    sideBar: {
        title: '¡INCLUYE TU EDIFICIO EN EL MAPA!',
        body: `Bienvenido al Mapa del Escándalo del Revestimiento de Edificios. Somos una comunidad de vecinos de voluntarios de propietarios afectados que utiliza datos facilitados públicamente para ilustrar la magnitud del escándalo del revestimiento de edificios. Este proyecto entró en vigor el 8 de abril de 2021. Es totalmente nuevo… ¡Añade tu edificio!
        `,
    },
    body: {
        int: {
            title: 'INVERSORES INTERNACIONALES',
            body: `Somos conscientes de que esta situación no está teniendo mucha o ninguna cobertura a nivel global. Mientras tanto, los promotores inmobiliarios continúan comercializando sus bienes inmuebles en convenciones celebradas en países como China, Rusia, los EAU, entre otros. Esto es totalmente inaceptable y nos gustaría disculparnos en nombre del Reino Unido… ¡Nuestro país quiere seguir dando la bienvenida a todo aquel que quiera vivir o invertir en él! 
            `,
        },
        who: {
            title: '¿QUIÉNES SOMOS?',
            body: 'Una comunidad de vecinos (propietarios) afectada por el escándalo del revestimiento de edificios. No formamos parte del sector ni de ningún partido político.'
        },
        what: {
            title: '¿QUÉ ES EL ESCÁNDALO DEL REVESTIMIENTO DE EDIFICIOS (Y QUIÉN SE VE AFECTADO)?',
            body: `El desastre de Grenfell Tower de junio de 2017 que se cobró 72 vidas provocó cambios en la normativa de construcción del Reino Unido y la intensificación de las medidas de seguridad contra incendios.
            Este incendio puso de manifiesto que muchos edificios habían sido revestidos con material combustible. Asimismo, se ha identificado un gran número de edificios por no cumplir con otras medidas contra incendios diseñadas para evitar la propagación de incendios horizontal y verticalmente en viviendas colindantes.
            Además de que estos edificios suponen un riesgo inminente de incendio para todos sus vecinos, los propietarios tienen que hacer frente a unos costes de reparación muy elevados y significativos, añadiendo además una subida extrema de los gastos de seguro y la posibilidad de tener que pagar por un equipo de vigilantes de incendios (personal que revisa los edificios 24/7 para comprobar que no haya ningún incendio).
            Los prestamistas hipotecarios, al no poder cuantificar los riesgos de incendios que suponen los distintos edificios, han dejado de ofrecer hipotecas para la compra de bienes inmuebles salvo si sus propietarios pueden demostrar la seguridad del edificio, normalmente mediante un certificado llamado EWS1.
            En febrero de 2021, el gobierno británico prometió una ayuda de más de 5 mil millones de libras esterlinas para los costes de reparación, pero son muchos los propietarios individuales que todavía tienen que hacer frente a unos costes muy elevados por haber comprado sus viviendas sin saber que estas habían sido construidas de forma ilícita.
            Nuestro objetivo es conectar a todas las personas afectadas por este problema y crear visibilidad. La función de ampliación está limitada y no se ha utilizado ningún dato personal de los edificios (código postal, número, etc.). No recopilamos datos personales, simplemente presentamos un resumen de los edificios afectados. Nuestra intención es utilizar estos datos para ejercer presión y mejorar los derechos de los propietarios.
            En nuestras preguntas frecuentes podrás encontrar más información
            `
        },
        press: {
            title: 'MEDIOS DE COMUNICACIÓN Y PRENSA',
            body: `Nos gustaría explicar y compartir lo que está sucediendo. Nuestro correo electrónico es: claddingscandalmap@gmail.com.`,
        }
    },
    form: {
        title: 'AÑADIR UN EDIFICIO',
        body: `Rellena el formulario y añadiremos tu edificio en el mapa tras realizar una verificación. Completa el formulario lo más detalladamente posible.
        Este proyecto utiliza datos facilitados por usuarios; aceptas que eres responsable de los datos que subas. NO INCLUYAS DATOS PERSONALES.
        `
    }
}

const ar = {
    buttons: {
        add: 'أضف مبنى',
        share: 'شارك',
        updated: 'Updated',
    },
    headers: {
        buildings: 'شارك',
        unitsAffected: 'الأبنية',
        wakingWatch: 'الوحدات المتضررة',
        totalMonth: 'نظام الوقاية و الاخلاء:الإجمالي التقديري / الشهري',
        developers: 'المطورون',
        raFormed: "تشكيل نقابة المقيمين",
        ratingsPerMonth: 'التقييمات الشهرية',
        ratingType: ' نوع التقييم',
        buildingHeight: 'تصنيف ارتفاع المباني',
        top5Remediation: 'مشاكل شائعة',
    },
    sideBar: {
        title: 'المحتوى الجانبي',
        body: `مرحبًا بك على خارطة فضيحة الاكساء. نحن مجموعة متطوعين من  المستأجرين المتضررين الذين يستخدمون بيانات التعهيد الجماعي لتوضيح حجم فضيحة الاكساء. دخل هذا المشروع حيز التنفيذ في 8 أبريل 2021، فهو جديد جداً - استمروا بالمشاركة. `,
    },
    body: {
        int: {
            title: 'المحتوى الرئيسي',
            body: `
            المستثمرون الدوليوننحن ندرك أن التغطية العالمية لهذا الوضع قليلة جدًا إن لم تكن معدومة. و لا يزال المطورون، في الوقت نفسه ، يسوقون عقاراتهم في المؤتمرات في دول كالصين وروسيا والإمارات العربية المتحدة وغيرها. و هذا غير مقبول ونود الاعتذار نيابة عن المملكة المتحدة - شعبنا يرحب بكم.`,
        },
        who: {
            title: 'من نحن؟',
            body: 'مجموعة من الناس (المستأجرين) المتضررين بفضيحة الاكساء، و لسنا ضمن هذه الصناعة و لا من السياسيين.'
        },
        what: {
            title: 'ما هي فضيحة الاكساء (ومن المتضرر منها)؟',
            body: ` أثارت كارثة برج جرينفيل في يونيو 2017 ، والتي أودت بحياة 72 شخصًا ، دعوات لتغيير لوائح قوانين البناء في المملكة المتحدة وتعزيز تدابير السلامة من الحرائق.
            كشفت الحرائق أن أعداداً كبيرة من المباني كانت مكسية بمواد قابلة للاشتعال بشكل خطير. كما وجدت كذلك العديد من المباني الغير مطابقة للمتطلبات الأخرى لسلامة المباني من الحرائق   والتي تهدف إلى منع الحرائق من الانتشار أفقيًا وعموديًا إلى الشقق المجاورة.
            و بما أن هذه المباني تشكل خطراً مباشراً للحريق على السكان ، يجد مالكي الشقق أنفسهم في مواجهة أعمال إصلاح مكثفة ومكلفة ، وأقساط تأمين على المباني مرتفعة للغاية ، وإمكانية ايجاد "نظام الوقاية و الاخلاء" و هو: دفع النقود لأشخاص للقيام بدوريات في المباني على مدار الساعة طوال أيام الأسبوع للتحقق من وجود حرائق .
            توقف مقرضو الرهن العقاري ، الذين يكافحون من أجل تحديد مخاطر الحريق في  المباني المختلفة ، عن إقراض المال لشراء العديد من العقارات ما لم يتمكن مُلاكها من إثبات أن المبنى آمن ، عادةً عن طريق نموذج "EWS1".
            و تعهدت حكومة المملكة المتحدة اعتبارًا من فبراير 2021 ، بأكثر من خمسة مليارات جنيه إسترليني لأعمال الإصلاح ، و لكن لا تزال التكاليف الباهظة  تقع على عاتق مستأجري الشقق الأفراد الذين اقتنوا عن غير قصد منازل مشيدة بشكل غير قانوني.
            إن هدفنا هو الوصل بين جميع المتضررين من مشكلة الاكساء الجارية ، وخلق رؤية. 
            تم قفل معيار التكبير / التصغير ( الزووم) على ارتفاع معين ، ولا يتم استخدام بيانات الموقع (الرمز البريدي ، العنوان ، إلخ). نحن لا نأخذ البيانات الشخصية و إنما فقط ملخص عن المواقع المتضررة. وننوي استخدام هذه البيانات لممارسة الضغط وتحسين حقوق المستأجر في القيام بذلك.`
        },
        press: {
            title: 'الصحافة و التعليقات',
            body: `نود التحدث إليك ومشاركة ما يحدث. راسلنا على: claddingscandalmap@gmail.com`,
        }
    },
    form: {
        title: 'نموذج المبنى:',
        body: `
        املأ النموذج وسنضيفك إلى الخارطة بعد التحقق. يرجى إكمال النموذج بأكبر قدر ممكن من التفاصيل. ويرجى التحقق من الخريطة الخاصة بموقعك أولاً.
        هذا المشروع هو بكامله  تعهيد جماعي ؛  و أنت توافق على أنك مسؤول عن البيانات التي تقوم بتحميلها.         
        لا تُدخل أي تفاصيل شخصية.
        
        `
    }
}

export const LOCALES = {
    en,
    es,
    zh,
    ru,
    ar,
}


