import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faUserTie, faUsers, faBed } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';

import { LineChart } from 'react-chartkick'
import 'chartkick/chart.js'

const COLORS = {
    'A1': '#FAD8D6',
    'B2': '#4C443C',
    'B3': '#CD5334',
    'B1': '#EDB88B',
    'B2': '#2E282A',
    'Unknown': '#17BEBB',
};

function ExposedData() {
    
  
  return <><p>We are very careful and cautious with uploaded data. <br />The only data we expose is as follows:</p>
  <p className="code">
  
      data: {`{`}<br />
      &nbsp;&nbsp;nodes: [{`{`}<br />
      &nbsp;&nbsp;&nbsp;&nbsp;  "lng":0.145, <br />
      &nbsp;&nbsp;&nbsp;&nbsp;  "lat":5.421 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;  "cladding": "y" <br />
      &nbsp;&nbsp;{`}`}, <br />
      &nbsp;&nbsp;...etc<br />
      &nbsp;&nbsp;],<br />
      &nbsp;&nbsp;units: 12345, <br />
      &nbsp;&nbsp;wakingwatch: 12.34, <br />
      &nbsp;&nbsp;dates: {'{'} <br />
      &nbsp;&nbsp;&nbsp;&nbsp; 2020-12: 1, <br />
      &nbsp;&nbsp;&nbsp;&nbsp;...etc<br />
      &nbsp;&nbsp;{'}'}<br />
      &nbsp;&nbsp;ratings: {'{'} <br />
      &nbsp;&nbsp;&nbsp;&nbsp; A1: 4, <br />
      &nbsp;&nbsp;&nbsp;&nbsp;...etc<br />
      &nbsp;&nbsp;{'}'},<br />
      &nbsp;&nbsp;residentsAssociation: 12.34, <br /> 
      &nbsp;&nbsp;heights: {'{'} <br />
      &nbsp;&nbsp;&nbsp;&nbsp; under-11: 99, <br />
      &nbsp;&nbsp;&nbsp;&nbsp;...etc<br />
      &nbsp;&nbsp;{'}'},<br />   
      &nbsp;&nbsp;remediations: {'['} <br />
      &nbsp;&nbsp;&nbsp;&nbsp; cladding, <br />
      &nbsp;&nbsp;&nbsp;&nbsp;...etc<br />
      &nbsp;&nbsp;{']'},<br />    
      &nbsp;&nbsp;ages: {'{'} <br />
      &nbsp;&nbsp;&nbsp;&nbsp; newbuild: 15.55, <br />
      &nbsp;&nbsp;&nbsp;&nbsp;...etc<br />
      &nbsp;&nbsp;{'}'},<br />    
    {`}`}
  </p>
  <p>This shows that the for each building, we expose only the derived longitude/latitude (abstracted to 3  decimal places) from a postcode lookup. We do not expose building postcode, title, or other data.</p>
  <p>The list of coordinates is also randomised every time the list is updated so as not to indicate their date added to the system.</p>
  <p>All other site data is stored in a write-only, secure database (it cannot be read).</p>
  </>
}

export default React.memo(ExposedData)