import React, { useState, createContext } from 'react';


import ReactFlagsSelect from 'react-flags-select';

import './App.css';
import Map from './components/Map';
import SideBar from './components/SideBar';
import { FirestoreProvider, FirestoreDocument } from "@react-firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Share from './components/Share';
import MslCmd from './MslCmd';
import Privacy from './Privacy';
import Media from './Media';
import Faq from './Faq';
import Nhs from './components/Nhs';
import Bills from './components/Bills';
import Protests from './components/Protests';
import EWS1Downgrade from './components/EWS1Downgrade';
import Articles from './components/Articles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import firebase from 'firebase';

import { StateProvider } from './Store';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD9JlzTehi2ScPwk1FwzUSPfIFEBxKh8jA",
    authDomain: "claddingscandalmap.firebaseapp.com",
    projectId: "claddingscandalmap",
    storageBucket: "claddingscandalmap.appspot.com",
    messagingSenderId: "378598239318",
    appId: "1:378598239318:web:56364de3293ed4efbb9ff7",
    measurementId: "G-P1JFLR94FG"
  };
firebase.initializeApp(firebaseConfig);
export const firestoreDB = firebase.firestore();

firebase.firestore().collection("quickstats").doc("totals").update({sitevisits: firebase.firestore.FieldValue.increment(1)});

function App() {

  
  return (
    <Router>
    <StateProvider>
    <FirestoreProvider {...firebaseConfig} firebase={firebase}>
    <div className="App">
      <div className="main-content">
      <div className="content-wrap">
      <Share />

        <Switch>
          <Route path="/msl_cmd">
            <MslCmd />
          </Route>

          <Route path="/privacy">
            <Privacy />
          </Route>

          

          <Route path="/media">
            <Media />
          </Route>

          <Route path="/articles">
            <Articles />
          </Route>

          <Route path="/faq">
            <Faq />
          </Route>

          <Route path="/nhs"> 
            <Nhs />
          </Route>

          <Route path="/ews1-downgrade">
            <EWS1Downgrade />
          </Route>

          <Route path="/protests">
            <Protests />
          </Route>

          

          <Route path="/bills">
            <Bills />
          </Route>

          

        <Route path="/">
         
            <FirestoreDocument path="/mapdata/default/">
              {d => {
                if (d.isLoading) {return "Loading";}
                if (!d.value) {return "no value";}
                return <Container>
                  <Row>
                  <Col>
                    <Map data={d.value} />
                  </Col>
                  <Col className="sidebar-wrap">
                    <SideBar data={d.value} />
                  </Col>  
                </Row>
                </Container>;
              }}
            </FirestoreDocument>
            
        </Route>
      </Switch>

      </div>
            </div>
            <footer>
              <div className="content-wrap">
                <h1>Links &amp; Info</h1>
                <a href="http://buildingsafetycrisis.org" title="Visit the Official Building Safety Crisis website" style={{ display: 'inline-block'}}>
      <img height="100px" src="https://firebasestorage.googleapis.com/v0/b/claddingscandalmap.appspot.com/o/assets%2Fbsc-logo.png?alt=media&token=b681b31b-9d8b-4575-bcf0-ee522819639e" alt="Building Safety Crisis logo" />
      </a>

                <p style={{ display: 'inline-block', marginLeft: '20px', width: '80%'}}>
                  © 2021 buildingsafetymap.co.uk<br />
                   Contact us: <a href="mailto:claddingscandalmap@gmail.com">claddingscandalmap@gmail.com</a> <br />
                   <a href="/nhs">NHS Survey</a> |&nbsp;
                   <a href="/articles">Location specific media and articles</a> |&nbsp;
                   <a href="/bills">EWS1 Bill Form</a> |&nbsp;
                   <a href="/protests">Add a demonstration</a> |&nbsp;

                   {/* <a href="/ews1-downgrade">EWS1 Downgrade Survey</a> |  */}
                   <a href="/faq">FAQ &amp; info</a> |&nbsp;
                   <a href="/media">Media &amp; press</a> |&nbsp;
                   <a href="/privacy">Privacy</a> |&nbsp;
                   <a href="https://firebasestorage.googleapis.com/v0/b/claddingscandalmap.appspot.com/o/assets%2Fmap-a4x9.pdf?alt=media&token=8b785103-e7eb-4d3d-a960-28ba27b1dad0">Download our map as a A0 (9xA4) printout</a> 
                </p>
              </div>
            </footer>       
          </div>
          
    </FirestoreProvider>
    </StateProvider>
    </Router>
  );
}

export default App;
