export const POSTCODE_REGISTERED = 'That postcode has been registered. It is likely your block is therefore in the system. Keep up the good work!';
export const DEVELOPERS = [
    'Higgins',
    'Lend Lease',
    'Ardmore Construction',
    'Galaventure',
    'Barratts',
    'Shepherds Bush Housing Association / Archer Hoblin',
    'Persimmon / McAleer & Rushe',
    'Cala / Miller',
    'Kier',
    'Denne',
    'Bellway',
    'Berkeley',
    'Crosby Homes a subsidiary of Berkeley group, sold on to Lendlease',
    'Ardmore/LLDC',
    'Abbotsound Developers',
    'Do not know',
];

export const MANAGERS = [
    'Triathlon',
    'Living City',
    'HML Group',
    'Mainstay',
    'Firstport',
    'Shepherds Bush Housing Association',
    'Trinity Estates',
    'James Gibb',    'Newlon Housing Trust',
    'Southern Housing Group',
    'Pinnacle',
    'Metro PM',
    'Crosby Homes Lend Lease',
    'East Village Management & Triathlon Homes',
    'Spring House/RTM',
    'Rendell & Ritter',
    'Clarion',
    'NHG',
    'Do not know',
]

export const REMEDIATIONS = [
'Spandrel Pannels',
'Composite Fire Doors',
'Balconies',
'Smoke Extract',
'Compartmentation',
'Boiler flues and vents',
'Cavity barriers',
'Fire-fighting lifts',
'Wet risers',
'Communal fire alarms',
'UPVC front entrance doors',
'Fire stopping ',
'Flat fire alarm',
'Fire Suppression ',
'Fire service access ',
'Emergency lighting ',
'Means of escape',
'Personal Emergency Evacuation Plans',
'Dissabled refuge systems',
'Evacuation aids - evac chairs',
'Premises Information Box',
'Gas supplies and Interlock',
'PV / solar pannels ',
'Refuge chutes ',
'Ventilation and air conditioning',
'Structural fire precautions',
'Cladding ',
'ACM',
'HPL',
'EPS Render ',
'Metal',
'Timber',
'GRP',
'Brickslip',
'Traditional Masonry',
'Insulation ',
'PIR - foam',
'PUR - foam ',
'Phenolic - foam',
'Expanded Polystyrene ',
'Stonewool',
'Other',
];

export const MPS = [
'Cherilyn,Mackrory,cherilyn.mackrory.mp@parliament.uk,@thisischerilyn,50.150563,-5.0919719,Conservative,TruroandFalmouth',
'George,Eustice,george.eustice.mp@parliament.uk,N/A,50.2382719,-5.2484666,Conservative,CamborneandRedruth',
'Steve,Double,steve.double.mp@parliament.uk,@stevedouble,50.3749991,-4.9347547,Conservative,StAustellandNewquay',
'Gary,Streeter,#N/A,@garystreeterSWD,50.3950128,-4.0007762,Conservative,SouthWestDevon',
'Johnny,Mercer,#N/A,@JohnnyMercerUK,50.4110128,-4.1487548,Conservative,PlymouthMoorView',
'Sheryll,Murray,#N/A,@sheryllmurray,50.4140128,-4.5287548,Conservative,SouthEastCornwall',
'Anthony,Mangnall,anthony.mangnall.mp@parliament.uk,@AnthonyMangnal1,50.4272845,-3.7062683,Conservative,Totnes',
//'Derek,Thomas,#N/A,@DerekThomasUK,50.2106472,-5.4966087,Conservative,StIves',
'Scott,Mann,#N/A,@scottmann4NC,50.4419899,-5.5177928,Conservative,NorthCornwall',
'Alex,Chalk,alex.chalk.mp@parliament.uk,@AlexChalkChelt,51.889908,-2.1174931,Conservative,Cheltenham',
'Kevin,Foster,#N/A,@kevin_j_foster,50.4461415,-3.6125934,Conservative,Torbay',
'Richard,Drax,richard.drax.mp@parliament.uk,N/A,50.5665991,-2.4628547,Conservative,SouthDorset',
'Bob,Seely,bob.seely.mp@parliament.uk,@IoWBobSeely,50.6712432,-1.4683321,Conservative,IsleofWight',
'Mel,Stride,mel.stride.mp@parliament.uk,@MelJStride,50.7000127,-3.9087762,Conservative,CentralDevon',
'Geoffrey,Cox,coxg@parliament.uk,@Geoffrey_Cox,50.7149992,-4.2717548,Conservative,TorridgeandWestDevon',
'Conor,Burns,#N/A,@ConorBurnsUK,50.7169992,-1.9217762,Conservative,BournemouthWest',
'Robert,Syms,#N/A,@RobertSyms,50.7230135,-2.0081566,Conservative,Poole',
'Tobias,Ellwood,#N/A,@Tobias_Ellwood,50.7249992,-1.8117547,Conservative,BournemouthEast',
'Simon,Jupp,simon.jupp.mp@parliament.uk,@simonjamesjupp,50.7570052,-3.3761751,Conservative,EastDevon',
'Chris,Loder,chris.loder.mp@parliament.uk,@chrisloder,50.7952038,-2.8916985,Conservative,WestDorset',
'Nick,Gibb,gibbn@parliament.uk,@NickGibbUK,50.7990127,-0.6077548,Conservative,BognorRegisandLittlehampton',
'Penny,Mordaunt,penny.mordaunt.mp@parliament.uk,@PennyMordaunt,50.8047147,-1.1669412,12,Conservative,PortsmouthNorth',
'Desmond,Swayne,#N/A,@DesmondSwayne,50.8060127,-1.6377762,Conservative,NewForestWest',
'Caroline,Dinenage,#N/A,@cj_dinenage,50.8067683,-1.1855995,Conservative,Gosport',
'Gillian,Keegan,#N/A,@GillianKeegan,50.8361254,-0.8137285,Conservative,Chichester',
'Tim,Loughton,loughtont@parliament.uk,@timloughton,50.8373386,-0.2911642,Conservative,EastWorthingandShoreham',
'Huw,Merriman,#N/A,@HuwMerriman,50.8473378,0.4426714,Conservative,BexhillandBattle',
'Suella,Braverman,suella.braverman.mp@parliament.uk,@SuellaBraverman,50.8501961,-1.2650888,Conservative,Fareham',
'Alan,Mak,#N/A,@AlanMakMP,50.8604301,-1.0031911,Conservative,Havant',
'Flick,Drummond,flick.drummond.mp@parliament.uk,N/A,50.8999991,-1.0327547,Conservative,MeonValley',
'Neil,Parish,#N/A,@neil_parish,50.9049389,-3.4939671,Conservative,TivertonandHoniton',
'Sally,AnnHart,Sallyann.hart.mp@parliament.uk,@SallyAnn1066,50.9100127,0.6482452,Conservative,HastingsandRye',
'Simon,Hoare,#N/A,@Simon4NDorset,50.9199595,-2.5403189,Conservative,NorthDorset',
'Marcus,Fysh,#N/A,@MarcusFysh,50.9421814,-2.6773832,Conservative,Yeovil',
'Nusrat,Ghani,nusrat.ghani.mp@parliament.uk,@Nus_Ghani,50.9422608,-0.0732404,Conservative,Wealden',
'Paul,Holmes,paul.holmes.mp@parliament.uk,@pauljholmes,50.9761312,-1.3817408,Conservative,Eastleigh',
'Rebecca,Pow,rebecca.pow.mp@parliament.uk,@pow_rebecca,51.0048696,-3.289893,Conservative,TauntonDeane',
'Mims,Davies,mims.davies.mp@parliament.uk,@mimsdavies,51.0052395,-0.2423704,Conservative,MidSussex',
'Damian,Hinds,#N/A,@DamianHinds,51.0052865,-0.9102629,Conservative,EastHampshire',
'Damian,Collins,#N/A,@DamianCollins,51.0574256,0.7188567,Conservative,FolkestoneandHythe',
'Selaine,Saxby,selaine.saxby.mp@parliament.uk,@SelaineSaxby,51.0617052,-4.2093084,Conservative,NorthDevon',
'Steve,Brine,steve.brine.mp@parliament.uk,@BrineMP,51.0641923,-1.3558111,Conservative,Winchester',
'Jeremy,Quin,#N/A,N/A,51.0684319,-0.3552285,Conservative,Horsham',
'John,Glen,#N/A,@JohnGlenUK,51.0797597,-1.843363,Conservative,Salisbury',
'Henry,Smith,#N/A,@HenrySmithUK,51.1197953,-0.2181424,Conservative,Crawley',
'Natalie,Elphicke,natalie.elphicke.mp@parliament.uk,@NatalieElphicke,51.1262792,1.2659701,Conservative,Dover',
'Ian,LiddellGrainger,ianlg@parliament.uk,N/A,51.1268991,-3.0282796,Conservative,BridgwaterandWestSomerset',
'Greg,Clark,gregclarkmp@parliament.uk,@GregClarkMP,51.1350223,0.2492831,Conservative,TunbridgeWells',
'Jeremy,Hunt,#N/A,@Jeremy_Hunt,51.1500126,-0.7087548,Conservative,SouthWestSurrey',
'Kit,Malthouse,kit.malthouse.mp@parliament.uk,@kitmalthouse,51.1833456,-1.4587548,Conservative,NorthWestHampshire',
'Helen,Grant,#N/A,@HelenGrantMP,51.2000126,0.5412452,Conservative,MaidstoneandTheWeald',
'James,Heappey,#N/A,@JSHeappey,51.2090078,-2.6652441,Conservative,Wells',
'Ranil,Jayawardena,#N/A,@TeamRanil,51.2093771,-0.8230045,Conservative,NorthEastHampshire',
'Crispin,Blunt,#N/A,@CrispinBlunt,51.2356229,-0.2464732,Conservative,Reigate',
'Angela,Richardson,angela.richardson.mp@parliament.uk,@AJRichardsonMP,51.2415639,-0.6004427,Conservative,Guildford',
'Leo,Docherty,#N/A,@LeoDochertyUK,51.2529272,-0.7936865,Conservative,Aldershot',
'Maria,Miller,#N/A,@MariaMillerUK,51.2560767,-1.142331,Conservative,Basingstoke',
'Claire,Coutinho,claire.coutinho.mp@parliament.uk,@ClaireCoutinho,51.2711802,-0.6755363,Conservative,EastSurrey',
'Laura,Trott,laura.trott.mp@parliament.uk,N/A,51.2739735,0.1503462,Conservative,Sevenoaks',
'Craig,Mackinlay,#N/A,@cmackinlay,51.3000125,1.3579122,Conservative,SouthThanet',
'Helen,Whately,#N/A,@Helen_Whately,51.3137179,0.8532778,Conservative,FavershamandMidKent',
'Jonathan,Lord,#N/A,@JonathanLord,51.3151453,-0.5855566,Conservative,Woking',
'Andrew,Murrison,#N/A,@AWMurrison,51.3235881,-2.48608,Conservative,SouthWestWiltshire',
'Chris,Philp,#N/A,@CPhilpOfficial,51.331025,-0.1155096,Conservative,CroydonSouth',
'Jacob,ReesMogg,jacob.reesmogg.mp@parliament.uk,@Jacob_Rees_Mogg,51.3333456,-2.5087548,Conservative,NorthEastSomerset',
'Michael,Gove,#N/A,@michaelgove,51.3359398,-0.7320374,Conservative,SurreyHeath',
'Chris,Grayling,chris.grayling.mp@parliament.uk,N/A,51.3363478,-0.3317926,Conservative,EpsomandEwell',
'John,Penrose,#N/A,@JohnPenroseNews,51.343452,-3.0750173,Conservative,WestonsuperMare',
'Dominic,Raab,#N/A,@DominicRaab,51.3500125,-0.3787548,Conservative,EsherandWalton',
'Gordon,Henderson,#N/A,N/A,51.3500125,0.7745782,Conservative,SittingbourneandSheppey',
'Danny,Kruger,danny.kruger.mp@parliament.uk,@danny__kruger,51.3591403,-2.0337979,Conservative,Devizes',
'Paul,Scully,#N/A,@scullyp,51.3620125,-0.2177762,Conservative,SuttonandCheam',
'Gareth,Bacon,gareth.bacon.mp@parliament.uk,@GarethBaconMP,51.3719893,0.0610921,Conservative,Orpington',
'Kelly,Tolhurst,#N/A,@KellyTolhurst,51.3810787,0.4514387,Conservative,RochesterandStrood',
'Laura,Farris,laura.farris.mp@parliament.uk,@Laura__Farris,51.3885327,-1.3434394,Conservative,Newbury',
'Ben,Spencer,ben.spencer.mp@parliament.uk,@DrBenSpencer,51.3950194,-0.6089905,Conservative,RunnymedeandWeybridge',
'Liam,Fox,#N/A,@LiamFox,51.3963158,-3.1314431,Conservative,NorthSomerset',
'Adam,Holloway,#N/A,@AdamHollowayMP,51.3973044,0.260828,Conservative,Gravesham',
'Bob,Stewart,bob.stewart.mp@parliament.uk,N/A,51.4007622,-0.0636777,Conservative,Beckenham',
'James,Sunderland,james.sunderland.mp@parliament.uk,@JamesSunderl,51.4060819,-0.7942841,Conservative,Bracknell',
'John,Redwood,#N/A,@johnredwood,51.4082812,-0.8695746,Conservative,Wokingham',
'Kwasi,Kwarteng,#N/A,@KwasiKwarteng,51.4250353,-0.4970208,Conservative,Spelthorne',
'Gareth,Johnson,#N/A,@GarethJohnsonMP,51.4344927,0.1768256,Conservative,Dartford',
'James,Brokenshire,#N/A,@jbrokenshire,51.436377,0.1281922,Conservative,OldBexleyandSidcup',
'Alun,Cairns,#N/A,@AlunCairnsMP,51.4471829,-3.5445033,Conservative,ValeofGlamorgan',
'Michelle,Donelan,#N/A,@michelledonelan,51.4595379,-2.1570143,Conservative,Chippenham',
'Alok,Sharma,#N/A,@AlokSharma_RDG,51.4600125,-0.9987762,Conservative,ReadingWest',
'Chris,Skidmore,chris.skidmore.mp@parliament.uk,@CSkidmoreUK,51.4619982,-2.5215096,Conservative,Kingswood',
'David,Evennett,#N/A,@DavidEvennett,51.4635532,0.1158085,Conservative,BexleyheathandCrayfordBexleyheathandCrayford',
'Adam,Afriyie,#N/A,@AdamAfriyie,51.4773308,-0.6431535,Conservative,Windsor',
'Greg,Hands,handsg@parliament.uk,@GregHands,51.4800125,-0.1987548,Conservative,ChelseaandFulham',
'Paul,Beresford,#N/A,N/A,51.2200351,-0.4475968,Conservative,MoleValley,annie.winsbury@parliament.uk;office@molevalleyconservatives.org.uk',
'Jamie,Wallis,jamie.wallis.mp@parliament.uk,@JamieWallisMP,51.5078847,-3.6683513,Conservative,Bridgend',
'Jackie,DoylePrice,jackie.doyleprice.mp@parliament.uk,@JackieDP,51.5080341,0.3105078,Conservative,Thurrock',
'Jack,Lopresti,jack.lopresti.mp@parliament.uk,@JackLopresti,51.5140472,-2.5781999,Conservative,FiltonandBradleyStoke',
'Nickie,Aiken,nickie.aiken.mp@parliament.uk,@twocitiesnickie,51.5144543,-0.094358751,Conservative,CitiesofLondonandWestminster',
'Theresa,May,#N/A,@theresa_may,51.5180628,-0.7637033,Conservative,Maidenhead',
'Stephen,Metcalfe,#N/A,@Metcalfe_SBET,51.5355125,0.4712452,Conservative,SouthBasildonandEastThurrock',
'John,Howell,#N/A,@JHowellUK,51.5361159,-0.9245928,Conservative,Henley',
'Boris,Johnson,boris.johnson.mp@parliament.uk,@BorisJohnson,51.5400125,-0.4487548,Conservative,UxbridgeandSouthRuislip',
'Rebecca,Harris,#N/A,@RebeccaHarrisMP,51.5445947,0.5360089,Conservative,CastlePoint',
'James,Gray,#N/A,@JGray,51.5471643,-1.9038189,Conservative,NorthWiltshire',
'James,Duddridge,#N/A,@JamesDuddridge,51.5500125,0.8012452,Conservative,RochfordandSouthendEast',
'Justin,Tomlinson,#N/A,@JustinTomlinson,51.5590125,-1.7907762,Conservative,NorthSwindon',
'Robert,Buckland,#N/A,@RobertBuckland,51.5590125,-1.7907548,Conservative,SouthSwindon',
'Julia,Lopez,#N/A,@JuliaLopezMP,51.5620129,0.1803876,Conservative,HornchurchandUpminster',
'Luke,Hall,#N/A,@LukeHall,51.5730125,-2.4857548,Conservative,ThornburyandYate',
'Mike,Freer,mike.freer.mp@parliament.uk,N/A,51.5742115,-0.2070468,Conservative,FinchleyandGoldersGreen',
'Kate,Griffiths,kate.griffiths.mp@parliament.uk,N/A,52.8127163,-1.6677656,Conservative,Burton',
'David,Johnston,david.johnston.mp@parliament.uk,@david4wantage,51.5872796,-1.4423977,Conservative,Wantage',
'David,Simmonds,david.simmonds.mp@parliament.uk,@DSimmonds_RNP,51.5979034,-0.4235803,Conservative,RuislipNorthwoodandPinner',
'Mark,Francois,#N/A,N/A,51.6000125,0.6912452,Conservative,RayleighandWickford',
'Joy,Morrissey,joy.morrissey.mp@parliament.uk,@joymorrissey,51.6068921,-0.6731252,Conservative,Beaconsfield',
'Steve,Baker,steve.baker.mp@parliament.uk,@SteveBakerHW,51.6347623,-0.7663047,Conservative,Wycombe',
'Theresa,Villiers,theresa@theresavilliers.co.uk,N/A,51.6578875,-0.2231801,Conservative,ChippingBarnet',
'Dean,Russell,dean.russell.mp@parliament.uk,@dean4watford,51.6614445,-0.4755491,Conservative,Watford',
'Alex,Burghart,alex.burghart.mp@parliament.uk,@alexburghart,51.6800124,0.2212452,Conservative,BrentwoodandOngar',
'Oliver,Dowden,#N/A,@OliverDowden,51.680842,-0.3450729,Conservative,Hertsmere',
// 'Cheryl,Gillan,#N/A,@CherylGillan,51.6940125,-0.6187548,Conservative,CheshamandAmersham',
'Geoffrey,CliftonBrown,cliftonbrowng@parliament.uk,@CliftonBrown_MP,51.7251895,-2.4739143,Conservative,TheCotswolds',
'Vicky,Ford,vicky.ford.mp@parliament.uk,@vickyford,51.7258857,0.4440947,Conservative,Chelmsford',
'John,Whittingdale,#N/A,@JWhittingdale,51.7263019,0.6611679,Conservative,Maldon',
'Gagan,Mohindra,gagan.mohindra.mp@parliament.uk,@gaganmohindra,51.7297026,-0.5511353,Conservative,SouthWestHertfordshire',
'Charles,Walker,#N/A,@CharlesWalkerMP,51.7365837,-0.0326609,Conservative,Broxbourne',
'Siobhan,Baillie,siobhan.baillie.mp@parliament.uk,@Siobhan_Baillie,51.7422744,-2.2407643,Conservative,Stroud',
'Simon,Hart,#N/A,@Simonhartmp,51.7450125,-4.7097548,Conservative,CarmarthenWestandSouthPembrokeshire',
'Mike,Penning,mike.penning.mp@parliament.uk,N/A,51.753788,-0.4992408,Conservative,HemelHempstead',
'Robert,Halfon,halfon4harlow@roberthalfon.com,@halfon4harlowMP,51.766002,0.075997,Conservative,Harlow',
'Mark,Harper,#N/A,@Mark_J_Harper,51.7855189,-2.7416232,Conservative,ForestofDean',
'Robert,Courts,#N/A,@robertcourts,51.7863364,-1.5127542,Conservative,Witney',
'Giles,Watling,#N/A,@GilesWatling,51.7917439,1.1519801,Conservative,Clacton',
'Julie,Marson,julie.marson.mp@parliament.uk,@JulieMarsonMP,51.7967556,-0.0934664,Conservative,HertfordandStortford',
'Priti,Patel,#N/A,@pritipatel,51.8013303,0.6018155,Conservative,Witham',
'Grant,Shapps,#N/A,@grantshapps,51.8024911,-0.2274548,Conservative,WelwynHatfield',
'Rob,Butler,rob.butler.mp@parliament.uk,@AylesburyTories,51.8152889,-0.850456,Conservative,Aylesbury',
'David,TCDavis,david.davies.mp@parliament.uk,@DavidTCDavies,51.8199237,-2.7525062,Conservative,Monmouth',
'Andrew,Griffith,Andrew@GriffithMP.com,@griffitha,51.8560124,-0.5677548,Conservative,ArundelandSouthDowns',
'Richard,Graham,#N/A,@RichardGrahamUK,51.8572102,-2.277532,Conservative,Gloucester',
'Bernard,Jenkin,#N/A,@bernardjenkin,51.9200124,1.0112452,Conservative,HarwichandNorthEssex',
'Will,Quince,will.quince.mp@parliament.uk,@willquince,51.8862047,0.8336073,Conservative,Colchester',
'James,Cleverly,#N/A,@JamesCleverly,51.8937249,0.5241882,Conservative,BraintreeBraintree',
'Oliver,Heald,#N/A,@OliverHealdUK,51.9166794,-0.0420878,Conservative,NorthEastHertfordshire',
'Andrew,Selous,#N/A,@AndrewSelous,51.9208336,-0.6804591,Conservative,SouthWestBedfordshire',
'Stephen,Crabb,#N/A,@SCrabbPembs,51.9357709,-4.835781,Conservative,PreseliPembrokeshire',
'Bim,Afolami,bim.afolami.mp@parliament.uk,@BimAfolami,51.9565874,-0.3462929,Conservative,HitchinandHarpenden',
'Laurence,Robertson,#N/A,@lrobertsonTewks,51.9925823,-2.1553218,Conservative,Tewkesbury',
'Greg,Smith,greg.smith.mp@parliament.uk,@gregsmith_uk,51.9976829,-0.9878317,Conservative,Buckingham',
'James,Cartlidge,#N/A,@jc4southsuffolk,52.0000123,0.8912452,Conservative,SouthSuffolk',
'Kemi,Badenoch,kemi.badenoch.mp@parliament.uk,@KemiBadenoch,52.0208274,0.2303309,Conservative,SaffronWalden',
'Iain,Stewart,#N/A,@iainastewart,52.0210124,-0.6957762,Conservative,MiltonKeynesSouth',
'Nadine,Dorries,#N/A,@NadineDorries,52.0269992,-0.3917548,Conservative,MidBedfordshire',
'Jesse,Norman,jesse.norman.mp@parliament.uk,@Jesse_Norman,52.0527936,-2.7586541,Conservative,HerefordandSouthHerefordshire',
'Victoria,Prentis,#N/A,@VictoriaPrentis,52.0641258,-1.3736467,Conservative,Banbury',
'Fay,Jones,fay.jones.mp@parliament.uk,@JonesyFay,52.0834347,-3.5841806,Conservative,BreconandRadnorshire',
'Andrea,Leadsom,#N/A,@andrealeadsom,52.1184931,-1.1587855,Conservative,SouthNorthamptonshire',
'Lucy,Frazer,lucy.frazer.mp@parliament.uk,@lucyfrazermp,52.1310123,0.3012452,Conservative,SouthEastCambridgeshire',
'Harriett,Baldwin,#N/A,@hbaldwin,52.1500123,-2.3087548,Conservative,WestWorcestershire',
'Nigel,Huddleston,#N/A,@HuddlestonNigel,52.1500123,-2.0587548,Conservative,MidWorcestershire',
'Thérèse,Coffey,#N/A,@theresecoffey,52.1501383,1.1326991,Conservative,SuffolkCoastal',
'Anthony,Browne,anthony.browne.mp@parliament.uk,@ab4scambs,52.1797284,-0.1871696,Conservative,SouthCambridgeshire',
'Robin,Walker,#N/A,@WalkerWorcester,52.1964933,-2.2802933,Conservative,Worcester',
'Nadhim,Zahawi,#N/A,@nadhimzahawi,52.1966407,-1.7480161,Conservative,StratfordonAvon',
'Bill,Wiggin,bill.wiggin.mp@parliament.uk,@BillWigginMP,52.1999992,-2.7087548,Conservative,NorthHerefordshire',
'Dan,Poulter,daniel.poulter.mp@parliament.uk,@DrDanPoulter,52.2000123,1.1412452,Conservative,CentralSuffolkandNorthIpswichNorth',
'Shailesh,Vara,#N/A,@ShaileshVara,52.2182697,0.0877144,Conservative,NorthWestCambridgeshire',
'Richard,Fuller,richard.fuller.mp@parliament.uk,N/A,52.2209992,-0.5827547,Conservative,NorthEastBedfordshire',
'Jo,Churchill,jo.churchill.mp@parliament.uk,@Jochurchill4,52.2392932,0.6835761,Conservative,BuryStEdmunds',
'Jeremy,Wright,#N/A,N/A,52.2500123,-1.4087548,Conservative,KenilworthandSoutham',
'Michael,Ellis,#N/A,@Michael_Ellis1,52.2499992,-0.9087547,Conservative,NorthamptonNorth',
'Matt,Hancock,matt.hancock.mp@parliament.uk,@MattHancock,52.2582347,0.3735244,Conservative,WestSuffolk',
'Chris,HeatonHarris,chris.heatonharris.mp@parliament.uk,@chhcalling,52.2642352,-1.2005965,Conservative,Daventry',
'Rachel,Maclean,#N/A,@redditchrachel,52.2959135,-1.9657065,Conservative,Redditch',
'Andrew,Lewer,#N/A,@ALewerMBE,52.3105296,-1.1171519,Conservative,NorthamptonSouth',
'Sajid,Javid,#N/A,@sajidjavid,52.3334808,-2.0966487,Conservative,Bromsgrove',
'Jonathan,Djanogly,#N/A,@JDjanogly,52.3410836,-0.2080062,Conservative,Huntingdon',
'Philip,Dunne,#N/A,@Dunne4Ludlow,52.3719824,-2.7272603,Conservative,Ludlow',
'Ian,Levy,ian.levy.mp@parliament.uk,N/A,52.3733662,-1.8010159,Conservative,BlythValley',
'Mark,Pawsey,#N/A,@MarkPawsey,52.3772879,-1.2872173,Conservative,Rugby',
'Mark,Garnier,#N/A,@Mark4WyreForest,52.3851968,-2.4179502,Conservative,WyreForest',
'Gary,Sambrook,gary.sambrook.mp@parliament.uk,@GarySambrook89,52.4000123,-1.9887762,Conservative,BirminghamNorthfield',
'Alec,Shelbrooke,#N/A,@AlecShelbrooke,52.4205954,-0.8238296,Conservative,ElmetandRothwell',
'Julian,Knight,#N/A,@julianknight15,52.4251203,-1.8176364,Conservative,Solihull',
'Peter,Aldous,#N/A,@peter_aldous,52.4309082,1.417403,Conservative,Waveney',
'Steve,Barclay,stephen.barclay.mp@parliament.uk,@SteveBarclay,52.4330123,0.3662452,Conservative,NorthEastCambridgeshire',
'Saqib,Bhatti,saqib.bhatti@parliament.uk,@bhatti_saqib,52.4450367,-1.6700629,Conservative,Meriden',
'James,Morris,#N/A,@JamesMorris,52.4500123,-2.0587548,Conservative,HalesowenandRowleyRegis',
'Suzanne,Webb,suzanne.webb.mp@parliament.uk,@Q66Suzi,52.4590005,-2.2183609,Conservative,Stourbridge',
'Mike,Wood,mike@mikewood.mp,@mikejwood,52.4899992,-2.1387548,Conservative,DudleySouth',
'Tom,Pursglove,tom.pursglove.mp@parliament.uk,@VotePursglove,52.4902112,-0.726666,Conservative,Corby',
'Alberto,Costa,#N/A,@AlbertoCostaMP,52.4999991,-1.2087548,Conservative,SouthLeicestershire',
'Elizabeth,Truss,#N/A,@trussliz,52.5000122,0.5912238,Conservative,SouthWestNorfolk',
'Marcus,Jones,#N/A,@Marcus4Nuneaton,52.5159673,-1.5524537,Conservative,Nuneaton',
'Richard,Bacon,#N/A,@richardbaconmp,52.5170685,1.1747341,Conservative,SouthNorfolk',
'Marco,Longhi,marco.longhi.mp@parliament.uk,@marcolonghi4dn,52.5200122,-2.1187548,Conservative,DudleyNorth',
'Shaun,Bailey,shaun.bailey.mp@parliament.uk,@Shaun4WBW,52.5300243,-2.0675096,Conservative,WestBromwichWest',
'Neil,O\'Brien,#N/A,@NeilDotObrien,52.5386833,-1.1497106,Conservative,Harborough',
'Nicola,Richards,nicola.richards.mp@parliament.uk,@Nicola4WBE,52.5400122,-1.9887548,Conservative,WestBromwichEast',
'Craig,Tracey,#N/A,@craig4nwarks,52.5614757,-1.7646617,Conservative,NorthWarwickshire',
'Paul,Bristow,paul.bristow.mp@parliament.uk,@paulbristow79,52.5850588,-0.4405371,Conservative,Peterborough',
'Stuart,Anderson,stuart.anderson.mp@parliament.uk,@Stuart4WolvesSW,52.5900122,-2.1787548,Conservative,WolverhamptonSouthWest',
'Brandon,Lewis,#N/A,@BrandonLewis,52.5913632,1.6840034,Conservative,GreatYarmouth',
'Gavin,Williamson,#N/A,@GavinWilliamson,52.6044997,-2.4347944,Conservative,SouthStaffordshire',
'Jane,Stevenson,jane.stevenson.mp@parliament.uk,@Jane_Stevenson_,52.6100122,-2.1187548,Conservative,WolverhamptonNorthEast',
'Eddie,Hughes,eddie.hughes.mp@parliament.uk,@EddieHughes4WN,52.6100122,-2.0187548,Conservative,WalsallNorth',
'Wendy,Morton,#N/A,@morton_wendy,52.6100122,-1.9387548,Conservative,AldridgeBrownhills',
'Luke,Evans,luke.evans.mp@parliament.uk,@drlukeevans,52.6171156,-1.4316959,Conservative,Bosworth',
'Christopher,Pincher,#N/A,@ChrisPincher,52.6280967,-1.7220216,Conservative,Tamworth',
'Alicia,Kearns,alicia.kearns.mp@parliament.uk,@aliciakearns,52.6424198,-0.7651508,Conservative,RutlandandMelton',
'Chloe,Smith,chloe@chloesmith.org.uk,@NorwichChloe,52.6500121,1.2912238,Conservative,NorwichNorth',
'Owen,Paterson,#N/A,@OwenPaterson,52.6518427,-3.2946459,Conservative,NorthShropshire',
'Craig,Williams,craig.williams.mp@parliament.uk,@craig4monty,52.6568935,-3.4723295,Conservative,Montgomeryshire',
'Mark,Pritchard,#N/A,@MPritchardUK,52.6693071,-2.56019,Conservative,TheWrekin',
'Lucy,Allan,#N/A,@lucyallan,52.6761198,-2.5319508,Conservative,Telford',
'George,Freeman,#N/A,N/A,52.6789057,0.9452922,Conservative,MidNorfolk',
'Michael,Fabricant,contactformonly,@Mike_Fabricant,52.6805802,-1.8439813,Conservative,Lichfield,noemail-contactform:https://www.michael.fabricant.mp.co.uk/contact/',
'Jerome,Mayhew,jerome.mayhew.mp@parliament.uk,@JeromeMayhew,52.6930298,1.1941016,Conservative,Broadland',
'Amanda,Milling,#N/A,@amandamilling,52.7069917,-2.0527648,Conservative,CannockChase',
'James,Wild,james.wild.mp@parliament.uk,@jamesowild,52.7116416,-0.072877,Conservative,NorthWestNorfolk',
'Daniel,Kawczynski,#N/A,@DKShrewsbury,52.7146816,-2.8197264,Conservative,ShrewsburyandAtcham',
'Edward,Argar,#N/A,N/A,52.73936,-1.2111997,Conservative,Charnwood',
'John,Hayes,#N/A,N/A,52.7500121,-0.1087548,Conservative,SouthHollandandTheDeepings',
'Jane,Hunt,jane.hunt.mp@parliament.uk,@JaneMHunt,52.7646718,-1.2562979,Conservative,Loughborough',
'Andrew,Bridgen,#N/A,@ABridgen,52.7706203,-1.5624319,Conservative,NorthWestLeicestershire',
'Nigel,Mills,#N/A,@nigelmills,52.7795643,-2.5598063,Conservative,AmberValley',
'Gareth,Davies,gareth.davies.mp@parliament.uk,@GarethDavies_MP,52.7999992,-0.5087548,Conservative,GranthamandStamford',
'Theo,Clarke,theo.clarke.mp@parliament.uk,@theodoraclarke,52.8050133,-2.1793531,Conservative,Stafford',
'Heather,Wheeler,#N/A,@HeatherWheeler,52.8220015,-1.8146482,Conservative,SouthDerbyshire',
'Duncan,Baker,duncan@duncanbaker.org.uk,@duncancbaker,52.8953462,1.0405893,Conservative,NorthNorfolk',
'William,Cash,#N/A,N/A,52.8974246,-2.1773848,Conservative,Stone',
'Ruth,Edwards,ruth.edwards.mp@parliament.uk,@RuthEdwardsMP,52.9125673,-1.1853182,Conservative,Rushcliffe',
'Maggie,Throup,maggie.throup.mp@parliament.uk,@maggie_erewash,52.9380717,-1.4293307,Conservative,Erewash',
'Simon,Baynes,simon.baynes.mp@parliament.uk,@baynes_simon,52.9402901,-3.1229218,Conservative,ClwydSouth',
'Amanda,Solloway,amanda.solloway.mp@parliament.uk,@ASollowayUK,52.9500121,-1.4987762,Conservative,DerbyNorth',
'Darren,Henry,darren.henry.mp@parliament.uk,@DarrenG_Henry,52.9733456,-1.329561,Conservative,Broxtowe',
'Tom,Randall,tom.randall.mp@parliament.uk,@Tom_Randall,52.9799899,-1.0987298,Conservative,Gedling',
'Jack,Brereton,#N/A,N/A,52.983345,-2.1587548,Conservative,StokeonTrentSouth',
'Aaron,Bell,aaron.bell.mp@parliament.uk,@AaronBell4NUL,53.0134603,-2.2642204,Conservative,NewcastleunderLyme',
'Jo,Gideon,jo.gideon.mp@parliament.uk,@jogideon,53.016679,-2.1920878,Conservative,StokeonTrentCentral',
'Jonathan,Gullis,jonathan.gullis.mp@parliament.uk,N/A,53.050012,-2.2087548,Conservative,StokeonTrentNorth',
'Caroline,Johnson,#N/A,@drcarolinej,53.050012,-0.5087548,Conservative,SleafordandNorthHykeham',
'Sarah,Atherton,sarah.atherton.mp@parliament.uk,@AthertonNWales,53.0511103,-3.0056776,Conservative,Wrexham',
'Karen,Bradley,#N/A,N/A,53.0715266,-2.2736653,Conservative,StaffordshireMoorlands',
'Robert,Jenrick,#N/A,@RobertJenrick,53.076317,-1.3623369,Conservative,Newark',
'Kieran,Mullan,kieran.mullan.mp@parliament.uk,@KieranMullanUK,53.0833451,-2.4920878,Conservative,CreweandNantwich',
'Lee,Anderson,lee.anderson.mp@parliament.uk,@LeeAndersonMP,53.0898317,-1.3244628,Conservative,Ashfield',
'Mark,Spencer,#N/A,@Mark_Spencer,53.1010832,-1.25193,Conservative,Sherwood',
'Edward,Timpson,edward.timpson.mp@parliament.uk,@edwardtimpson,53.1211312,-2.691427,Conservative,Eddisbury',
'Sarah,Dines,sarah.dines.mp@parliament.uk,@Dines4Dales,53.1297914,-2.2673909,Conservative,DerbyshireDales',
'Ben,Bradley,ben.bradley.mp@parliament.uk,@BBradley_Mans,53.1386704,-1.2285247,Conservative,Mansfield',
'David,Jones,ted.wilson@parliament.uk,@DavidJonesMP,53.143068,-3.4693108,Conservative,ClwydWest',
'Matt,Warman,matt.warman.mp@parliament.uk,@mattwarman,53.14878,0.25767,Conservative,BostonandSkegness',
'Fiona,Bruce,#N/A,N/A,53.162453,-2.2444237,Conservative,Congleton',
'Karl,McCartney,karl.mccartney.mp@parliament.uk,@karlmccartneyMP,53.2183421,-0.5905157,Conservative,Lincoln',
'Lee,Rowley,#N/A,@Lee4NED,53.2227932,-1.5807353,Conservative,NorthEastDerbyshire',
'Mark,Fletcher,mark.fletcher.mp@parliament.uk,N/A,53.2306778,-1.3097248,Conservative,Bolsover',
'Rob,Roberts,rob.roberts.mp@parliament.uk,N/A,53.252012,-3.2017548,Conservative,Delyn',
'James,Davies,james.davies.mp@parliament.uk,@JamesDavies,53.2574992,-3.4159768,Conservative,ValeofClwyd',
'David,Rutley,#N/A,@DavidRutley,53.2611891,-2.1628255,Conservative,Macclesfield',
'Virginia,Crosbie,virginia.crosbie.mp@parliament.uk,N/A,53.2649991,-4.3717548,Conservative,YnysMôn',
'Robin,Millar,robin.millar.mp@parliament.uk,@RobinMillarMP,53.302012,-3.8137548,Conservative,Aberconwy',
'Brendan,Clarke-Smith,brendan.clarkesmith.mp@parliament.uk,@Bren4Bassetlaw,53.3490993,-1.2602615,Conservative,Bassetlaw',
'Andy,Carter,andy.carter.mp@parliament.uk,@MrAndy_Carter,53.366679,-2.5587548,Conservative,WarringtonSouth',
'Victoria,Atkins,#N/A,N/A,53.3671889,-0.0176531,Conservative,LouthandHorncastle',
'Robert,Largan,mail@robertlargan.co.uk,@robertlargan,53.3676689,-1.984072,Conservative,HighPeak',
'Edward,Leigh,#N/A,@EdwardLeighMP,53.3961946,-0.7838735,Conservative,Gainsborough',
'Alexander,Stafford,alexander.stafford.mp@parliament.uk,@Alex_Stafford,53.430012,-1.3657762,Conservative,RotherValley',
'Nick,Fletcher,nick.fletcher.mp@parliament.uk,N/A,53.4919992,-1.1417547,Conservative,DonValley',
'James,Grundy,james.grundy.mp@parliament.uk,N/A,53.4991253,-2.5591093,Conservative,Leigh',
'Miriam,Cates,miriam.cates.mp@parliament.uk,N/A,53.4959401,-1.6238708,Conservative,PenistoneandStocksbridge',
'Martin,Vickers,#N/A,@MartinVickers,53.5574379,-0.0588103,Conservative,Cleethorpes',
'Lia,Nici,lia.nici.mp@parliament.uk,@lia_nici,53.5600119,-0.0887548,Conservative,GreatGrimsby',
'Chris,Clarkson,chris.clarkson.mp@parliament.uk,@ChrisClarksonMP,53.5699991,-2.2187548,Conservative,HeywoodandMiddleton',
'Christian,Wakeford,christian.wakeford.mp@parliament.uk,@Christian4BuryS,53.5799992,-2.3077548,Conservative,BurySouth',
'Holly,MumbyCroft,holly.mumbycroft.mp@parliament.uk,N/A,53.5821208,-0.7203411,Conservative,Scunthorpe',
'Mark,Logan,mark.logan.mp@parliament.uk,N/A,53.5870119,-2.4187548,Conservative,BoltonNorthEast',
'James,Daly,james.daly.mp@parliament.uk,@JamesDalyMP,53.6069991,-2.3077548,Conservative,BuryNorth',
'Damien,Moore,#N/A,N/A,53.634239,-3.0722343,Conservative,Southport',
'Imran,AhmadKhan,imran.mp@parliament.uk,@imranahmadkhan,53.6758249,-1.5452499,Conservative,Wakefield',
'Mark,Eastwood,mark.eastwood.mp@parliament.uk,@mark4dewsbury,53.6829467,-1.6593608,Conservative,Dewsbury',
'Craig,Whittaker,#N/A,@CWhittaker_MP,53.7049992,-1.9457548,Conservative,CalderValley',
'Jake,Berry,jake.berry.mp@parliament.uk,@JakeBerry,53.7080119,-2.2487548,Conservative,RossendaleandDarwen',
'Katherine,Fletcher,katherine.fletcher.mp@parliament.uk,@K_Fletcher_MP,53.7270256,-2.7782694,Conservative,SouthRibble',
'Andrea,Jenkyns,#N/A,@andreajenkyns,53.7410813,-1.6384175,Conservative,MorleyandOutwood',
'Sara,Britcliffe,sara.britcliffe.mp@parliament.uk,@SarBritcliffeMP,53.7607222,-2.4598068,Conservative,Hyndburn',
'Scott,Benton,scott.benton.mp@parliament.uk,@ScottBentonMP,53.7670118,-2.9227548,Conservative,BlackpoolSouth',
'Antony,Higginbotham,antony.higginbotham.mp@parliament.uk,@antony_hig,53.7742085,-2.2973309,Conservative,Burnley',
'Nigel,Adams,#N/A,@nadams,53.7805127,-1.0906149,Conservative,SelbyandAinsty',
'Mark,Menzies,#N/A,N/A,53.793396,-2.9977357,Conservative,Fylde',
'Stuart,Andrew,#N/A,@StuartAndrew,53.798306,-1.6896983,Conservative,Pudsey',
'Paul,Maynard,#N/A,@PaulMaynardUK,53.8255229,-3.0754612,Conservative,BlackpoolNorthandCleveleys',
'Graham,Stuart,#N/A,@grahamstuart,53.8410817,-0.4493456,Conservative,BeverleyandHolderness',
'Robbie,Moore,robbie.moore.mp@parliament.uk,@_RobbieMoore,53.8641735,-1.9483894,Conservative,Keighley',
'Greg,Knight,sothcottt@parliament.uk,@GregKnight,53.873712,-0.7570448,Conservative,EastYorkshire',
'Andrew,Stephenson,#N/A,@Andrew4Pendle,53.8790527,-2.2595696,Conservative,Pendle',
'Ben,Wallace,wallaceb@parliament.uk,@BWallaceMP,53.9029992,-2.7807548,Conservative,WyreandPrestonNorth',
'Julian,Smith,#N/A,@JulianSmithUK,53.9552604,-2.0395462,Conservative,SkiptonandRipon',
'Julian,Sturdy,#N/A,@JulianSturdy,53.9580118,-1.0907762,Conservative,YorkOuter',
'Andrew,Jones,#N/A,@AJonesMP,53.9890117,-1.4827548,Conservative,HarrogateandKnaresborough',
'David,Morris,#N/A,@davidmorrisml,54.0692441,-2.867327,Conservative,MorecambeandLunesdale',
'Simon,Fell,simon.fell.mp@parliament.uk,@simonfell,54.1003735,-3.2578092,Conservative,BarrowandFurness',
'Kevin,Hollinrake,#N/A,@kevinhollinrake,54.1340506,-1.124916,Conservative,ThirskandMalton',
'Trudy,Harrison,#N/A,N/A,54.3876702,-3.6575635,Conservative,Copeland',
'Rishi,Sunak,#N/A,@RishiSunak,54.4071605,-1.7540076,Conservative,RichmondYorks',
'Robert,Goodwill,#N/A,N/A,54.4805991,-0.6368141,Conservative,ScarboroughandWhitby',
'Matt,Vickers,matt.vickers.mp@parliament.uk,@Matt_VickersMP,54.5230116,-1.3307762,Conservative,StocktonSouth',
'Peter,Gibson,peter.gibson.mp@parliament.uk,@Gibbo4Darlo,54.5312395,-1.5883678,Conservative,Darlington',
'Simon,Clarke,#N/A,@SimonClarkeMP,54.5399982,-1.1565096,Conservative,MiddlesbroughSouthandEastCleveland',
'Jacob,Young,jacob.young.mp@parliament.uk,@JacobYoungMP,54.6027908,-1.1086246,Conservative,Redcar',
'Mark,Jenkinson,mark.jenkinson.mp@parliament.uk,@markjenkinsonmp,54.6378381,-3.8290431,Conservative,Workington',
'Paul,Howell,paul.howell.mp@parliament.uk,@PaulHowellMP,54.6591356,-1.4692132,Conservative,Sedgefield',
'Richard,Holden,richard.holden.mp@parliament.uk,@RicHolden,54.685301,-2.0779012,Conservative,NorthWestDurham',
'Neil,Hudson,neil.hudson.mp@parliament.uk,@DrNeilHudson,54.7000116,-2.7087548,Conservative,PenrithandTheBorder',
'John,Stevenson,john.stevenson.mp@parliament.uk,N/A,54.9000268,-2.9718726,Conservative,Carlisle',
'Guy,Opperman,#N/A,@GuyOpperman,54.9663484,-2.1383698,Conservative,Hexham',
'Alister,Jack,#N/A,@ScotSecofState,55.0481441,-4.5829208,Conservative,DumfriesandGalloway',
'David,Mundell,#N/A,@DavidMundellDCT,55.2000114,-3.5147548,Conservative,DumfriesshireClydesdaleandTweeddale',
'John,Lamont,#N/A,@John2Win,55.7760113,-2.4197548,Conservative,BerwickshireRoxburghandSelkirk',
'Anne,MarieTrevelyan,annemarie.trevelyan.mp@parliament.uk,@annietrev,55.7783701,-2.0263175,Conservative,BerwickuponTweed',
'Andrew,Bowie,#N/A,@AndrewBowie_MP,57.2500108,-3.2987548,Conservative,WestAberdeenshireandKincardine',
'Douglas,Ross,#N/A,@Douglas4Moray,57.3990531,-3.7676842,Conservative,Moray',
'David,Duguid,#N/A,@david_duguid,57.4719992,-2.4597546,Conservative,BanffandBuchan',
].map((mp)=>mp.split(','));


export const MPS_NO_VOTE = [
    //'Graham,Brady,,@SirGrahamBrady,53.3941,-2.34208,Conservative ,AltrinchamandSaleWest',
    'Felicity,Buchan,,@FelicityBuchan,51.503502,-0.193936,Conservative ,Kensington',
    'Tracey,Crouch,,@tracey_crouch,51.3372,0.491617,Conservative ,ChathamandAylesford',
    'Dehenna ,Davison,,@DehennaDavison,54.634701,-1.74458,Conservative ,BishopAuckland',
    //'Iain,Duncan-Smith,,@MPIainDS,51.6143,0.009636,Conservative ,ChingfordandWoodfordGreen',
    'Ben,Everitt,,@Ben_Everitt,52.065701,-0.747902,Conservative ,MiltonKeynesNorth',
    'Andrew ,Mitchell,,N/A,52.5625,-1.82081,Conservative ,SuttonColdfield',
    'Andrew ,Percy,,N/A,53.621498,-0.749279,Conservative ,BriggandGoole',
    'Jim,Shannon,,@JimShannonMP,54.3709776,-5.5605041,Democratic Unionist Party,Strangford',
    'Graham,Stringer,,@gstringermp,53.511101,-2.23338,Labour,BlackleyandBroughton',
].map((mp)=>mp.split(','));

export const MPS_SNP= [
    'Name,,Screen name,Latitude,Longitude,Party,Constituency',
    'Mhairi,Black,@MhairiBlack,,,Scottish National Party,Paisley and Renfrewshire South',
    'Joanna,Cherry QC,@joannaccherry,,,Scottish National Party,Edinburgh South West',
    'Ian,Blackford,@Ianblackford_MP,,,Scottish National Party,"Ross, Skye and Lochaber"',
    'Pete,Wishart,@PeteWishart,,,Scottish National Party,Perth and North Perthshire',
    'Alyn,Smith,@AlynSmith,,,Scottish National Party,Stirling',
    'Angus,MacNeil,@AngusMacNeilSNP,,,Scottish National Party,Na h-Eileanan an Iar',
    'Philippa,Whitford,@Dr_PhilippaW,,,Scottish National Party,Central Ayrshire',
    'Tommy,Sheppard,@TommySheppard,,,Scottish National Party,Edinburgh East',
    'Stewart,Malcolm-McDonald,@StewartMcDonald,,,Scottish National Party,Glasgow South',
    'John,Nicolson,@MrJohnNicolson,,,Scottish National Party,Ochil and South Perthshire',
    'Alison,Thewliss,@alisonthewliss,,,Scottish National Party,Glasgow Central',
    'Stewart,Hosie,@StewartHosieSNP,,,Scottish National Party,Dundee East',
    'Kirsty,Blackman,@KirstySNP,,,Scottish National Party,Aberdeen North',
    'Carol,Monaghan,@CMonaghanSNP,,,Scottish National Party,Glasgow North West',
    'Kenny,MacAskill,@KennyMacAskill,,,Scottish National Party,East Lothian',
    'Chris,Law,@ChrisLawSNP,,,Scottish National Party,Dundee West',
    'Deidre,Brock,@DeidreBrock,,,Scottish National Party,Edinburgh North and Leith',
    'Patrick,Grady,@GradySNP,,,Scottish National Party,Glasgow North',
    'Peter,Grant,@PeterGrantMP,,,Scottish National Party,Glenrothes',
    'Chris,Stephens,@ChrisStephens,,,Scottish National Party,Glasgow South West',
    'Drew,Hendry,@drewhendrySNP,,,Scottish National Party,"Inverness, Nairn, Badenoch and Strathspey"',
    'Kirsten,Oswald,@kirstenoswald,,,Scottish National Party,East Renfrewshire',
    'Neil,Gray,@neilgraysnp,,,Scottish National Party,Airdrie and Shotts',
    'Brendan,O\'Hara,@BrendanOHaraMP,,,Scottish National Party,Argyll and Bute',
    'Douglas,Chapman,@DougChapmanSNP,,,Scottish National Party,Dunfermline and West Fife',
    'Gavin,Newlands,@GavNewlandsSNP,,,Scottish National Party,Paisley and Renfrewshire North',
    'Amy,Callaghan,@AmyCallaghanSNP,,,Scottish National Party,East Dunbartonshire',
    'Lisa,Cameron,@DrLisaCameronMP,,,Scottish National Party,"East Kilbride, Strathaven and Lesmahagow"',
    'Martin,Docherty-Hughes,@MartinJDocherty,,,Scottish National Party,West Dunbartonshire',
    'Anne,McLaughlin,@AnneMcLaughlin,,,Scottish National Party,Glasgow North East',
    'Patricia,Gibson,@PGibsonSNP,,,Scottish National Party,North Ayrshire and Arran',
    'Stuart,McDonald,@Stuart_McDonald,,,Scottish National Party,"Cumbernauld, Kilsyth and Kirkintilloch East"',
    'Ronnie,Cowan,@ronniecowan,,,Scottish National Party,Inverclyde',
    'Alan,Brown,@AlanBrownSNP,,,Scottish National Party,Kilmarnock and Loudoun',
    'David,Linden,@DavidLinden,,,Scottish National Party,Glasgow East',
    'Marion,Fellows,@marionfellows,,,Scottish National Party,Motherwell and Wishaw',
    'Martyn,Day,@MartynDaySNP,,,Scottish National Party,Linlithgow and East Falkirk',
    'Johnny,McNally,@JohnMcNallySNP,,,Scottish National Party,Falkirk',
    'Owen,Thompson,@OwenThompson,,,Scottish National Party,Midlothian',
    'Neale,Hanvey,@JNHanvey,,,Alba Party,Kirkcaldy and Cowdenbeath',
    'Stephen,Flynn,@StephenFlynnSNP,,,Scottish National Party,Aberdeen South',
    'Dave,Doogan,@DaveDooganSNP,,,Scottish National Party,Angus',
    'Steven,Bonnar,@StevenBonnarSNP,,,Scottish National Party,"Coatbridge, Chryston and Bellshill"',
    'Richard,Thomson,@RThomsonMP,,,Scottish National Party,Gordon',
    'Hannah,Bardell,@HannahB4LiviMP,,,Scottish National Party,Livingston',
    'Angela ,Crawley,@AngelaCrawley30,,,Scottish National Party,Lanark and Hamilton East',
    'Allan,Dorans,@allandoransMP,,,Scottish National Party,"Aye, Carrick and Cumnock"',
].map((mp)=>mp.split(','));


export const MPS_LEDGES = [
    `Graham,Brady,@SirGrahamBrady,53.3941,-2.34208,Conservative ,AltrinchamandSaleWest`,
    `Sarah,Jones,sarah.jones.mp@parliament.uk,@LabourSJ,51.36901,-0.06275,Labour,Croydon Central,`,
`Royston,Smith,royston.smith.mp@parliament.uk,@Royston_Smith,50.91668,-1.35875,Conservative,Southampton, Itchen`,
`Hilary,Benn,bennh@parliament.uk,@hilarybennmp,53.80612,-1.67582,Labour,Leeds Central`,
`Stephen,McPartland,stephen@stephenmcpartland.co.uk,@SMcPartland,51.90267,-0.22785,Conservative,Stevenage,`,
`Matthew,Pennycook,matthew.pennycook.mp@parliament.uk,@mtpennycook,51.48301,0.019245,Labour,Greenwich and Woolwich,`,
`Sir Iain,Duncan Smith,Iain.duncansmith.mp@parliament.uk,@MPIainDS,51.61701,-0.00575,Conservative,Chingford and Woodford Green,`,
`Shabanha,Mahmood,shabana.mahmood.mp@parliament.uk,@ShabanaMahmood,52.49001,-1.87875,Labour,Birmingham, Ladywood,`,
`Bob,Blackman,bob.blackman.mp@parliament.uk,@BobBlackman,51.60601,-0.32175,Conservative,Harrow East,`,
`Barry,Gardiner,gardinerb@parliament.uk,@BarryGardiner,51.57001,-0.29875,Labour,Brent North`,
`Derek,Thomas,derek.thomas.mp@parliament.uk,@DerekThomasUK,50.21066,-5.49659,Conservative,St Ives,`,
`Daisy,Cooper,daisy.cooper.mp@parliament.uk,@libdemdaisy,51.74727,-0.34932,Lib Dem,St Albans`,
`Rebecca,Long Bailey,rebecca.longbailey.mp@parliament.uk,@RLong_Bailey,53.51001,-2.34875,Labour,Salford and Eccles`,
`Sir Robert,Neill,bob.neill.mp@parliament.uk,@neill_bob,51.39841,0.036245,Conservative,Bromley and Chislehurst`,
`Paul,Blomfield,paul.blomfield.mp@parliament.uk,@PaulBlomfieldMP,53.39584,-1.63954,Labour,Sheffield Central`,
`Stephen,Timms,timmss@parliament.uk,@stephenctimms,51.53523,0.033048,Labour,East Ham`,
`Stephen,Doughty,stephen.doughty.mp@parliament.uk,@SDoughtyMP,51.46901,-3.13575,Labour & Cooperative,Cardiff South and Penarth`
].map((mp)=>mp.split(','));