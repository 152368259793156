import React from 'react'
import { Button } from 'react-bootstrap';
import { FirestoreCollection } from "@react-firebase/firestore";
import firebase from 'firebase';

const ALLOWED_RATINGS = ['A1', 'A2', 'A3', 'B1', 'B2', 'unnknown'];

function MslCmd() {
    let allItems = [];
    let dataToProceess;
    let rowsToValidate = [];
    let units = 0;
    let wakingwatch = 0;
    let wakingwatchB2 = 0;
    let dates = {};
    let ratings = {};
    let total = 0;
    let residentsAssociation = 0;
    let costPassedToLeaseholder = {};
    let heights = {};
    let ages = {};
    let remediations = {};
    let eligibilityPercent = 0;
    let dateAnimation = [];

    const setUnits = (data) => {
        let sum = 0;
        data.forEach(site => {
            sum += site.units
        });
        units = sum;
    }

    const createOrUpdateKey = (object, key) => {
        if (object[key]) {
            object[key] = object[key] + 1;
        }
        else {
            object[key] = 1;
        }
    }

    const setWW = (data) => {
        let yes = 0;
        let no = 0;
        data.forEach(site => site.wakingwatch === 'yes' ? yes += 1 : no += 1);
        const total = yes + no;
        const percentYes = yes / total * 100; 
        wakingwatch = percentYes.toFixed(2);
        setWWRating(data, 'A1');
        setWWRating(data, 'A2');
        setWWRating(data, 'A3');
        setWWRating(data, 'B1');
        setWWRating(data, 'B2');
    }
    const setWWRating = (data, rating) => {
        let yes = 0;
        let no = 0;
        data.filter((site) => site.rating === rating && site.height === '18+').forEach(site => site.wakingwatch === 'yes' ? yes += 1 : no += 1);
        const total = yes + no;
        const percentYes = yes / total * 100; 
        wakingwatchB2 = percentYes.toFixed(2);
        console.log(rating+' with waking watch: ',percentYes.toFixed(2)+'%')
    }

    const setRA = (data) => {
        let yes = 0;
        let no = 0;
        data.forEach(site => site.ra === 'yes' ? yes += 1 : no += 1);
        const total = yes + no;
        const percentYes = yes / total * 100; 
        residentsAssociation = percentYes.toFixed(2);
    }

    const buildSimpleKey = (string) => {
        const key = string.trim().replace(/\s/g, '').toLowerCase().substring(0,4);
        // console.log('KEY: ',key)
        return key;
    }

    const calculateDevelopers = (data) => {
        let managerList = [];
        let developerList = [];
        const managers = {};
        const developers = {}

        data.forEach((site, i) => {
            developerList.push(site.developer)
            managerList.push(site.management)
        });

        managerList = new Set(managerList);
        developerList = new Set(developerList);

        managerList.forEach((manager) => {
            if (!manager) { return } 
            const key = buildSimpleKey(manager);
            if (managers[key]) {
                managers[key] = managers[key] + 1;
            }
            else {
                managers[key] = 1;
            }
        });

        developerList.forEach((developer) => {
            if (!developer) { return } 
            const key = buildSimpleKey(developer);
            createOrUpdateKey(developers, key);
        });

        console.log('developers: ',developers)
    }

    const calculateRatings = (data) => {
        data.forEach((nodeData) => {
            if (!ALLOWED_RATINGS.includes(nodeData.rating)) { return; }
            createOrUpdateKey(ratings, nodeData.rating);
        });
        console.log('ratings is ',ratings)
    }

    const calculateHeights = (data) => {
        data.forEach((nodeData) => {
            if (!nodeData.height) { return; }
            createOrUpdateKey(heights, nodeData.height);
        });
        heights = {
            'under-11': heights['under-11'],
            '11-18': heights['11-18'],
            '18+': heights['18+'],
        };
        console.log('heights is ',heights)
    }

    const calculateAges = (data) => {
        let total = 0;
        data.forEach((nodeData) => {
            if (!nodeData.age) { return; }
            total += 1;
            createOrUpdateKey(ages, nodeData.age);
        });

        ages = {
            'Newbuild': parseFloat((100 / total * ages['newbuild']).toFixed(2)),
            '5-10': parseFloat((100 / total * ages['10']).toFixed(2)),
            '10-20': parseFloat((100 / total * ages['20']).toFixed(2)),
            '20+': parseFloat((100 / total * ages['21']).toFixed(2)),
        };
        console.log('ages is ',ages)
    }

    const calculatecostPassedToLeaseholder = (data) => {
        data.forEach((nodeData) => {
            if (!nodeData.costspassedtoleaseholder) { 
                costPassedToLeaseholder['dontknow'] ? costPassedToLeaseholder['dontknow'] += 1 : costPassedToLeaseholder['dontknow'] = 1;
                return;
             }
            if (costPassedToLeaseholder[nodeData.costspassedtoleaseholder]) {
                costPassedToLeaseholder[nodeData.costspassedtoleaseholder] += 1;
            }
            else {
                costPassedToLeaseholder[nodeData.costspassedtoleaseholder] = 1;
            }
        });
        console.log('costPassedToLeaseholder is ',costPassedToLeaseholder)
    }

    const calculateDates = (data) => {
        data.forEach((nodeData) => {
            if (!nodeData.date) {return;}
            const dateKeyParts = nodeData.date.split('-');

            if (dateKeyParts[0] < 2019 || dateKeyParts[0] > 2021) { return }
            const dateKey = `${dateKeyParts[0]}-${dateKeyParts[1]}`;

            // Dissallow malformed keys
            if (dateKey.length > 8) {return;}
            createOrUpdateKey(dates, dateKey);
        });
    }

    const saveDataToDb = () => {
        // Verify any that need it
        rowsToValidate.forEach((uid) => {
            console.log('Needs to be verified!!!')

            firebase.firestore().collection("sites").doc(uid).update({verified: true}).then((ref) => { 
                console.log('ALL DONE: '+uid)
            });
        });

        // Save the map data


        firebase.firestore().collection("mapdata").doc('default')
            .set({
                updated: firebase.firestore.Timestamp.now(),
                units,
                wakingwatch,
                dates,
                ratings,
                residentsAssociation,
                heights,
                remediations,
                ages,
                eligibilityPercent,
                demonstrations,
                articles,
                nodes: allItems
                    //.filter((item)=>item.lat && item.lng)
                    .filter((item)=>!item.suspicious)
                    .map(
                        (nodeData) => JSON.stringify({
                            lat: nodeData.lat ? parseFloat(nodeData.lat.toFixed(3)) : null,
                            lng: nodeData.lat ? parseFloat(nodeData.lng.toFixed(3)) : null,
                            cladding: nodeData.remediation_cladding ? 'y' : 'n',
                })),
            })
            .then((ref) => { 
                console.log('MAP DATA BUILT!');
            })  
        
        firebase.firestore().collection("mapdata").doc('animation')
            .set({
                nodes: dateAnimation.map((nodeData) => JSON.stringify({ lat: nodeData.lat, lng: nodeData.lng, date: nodeData.date })),
            })
            .then((ref) => { 
                console.log('MAP ANIMATION DATA BUILT!');
            })  
    }

    const shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
      }

    const calculateRemediations = (allItems) => {
        const remediationKeys = {};
        let totalBuildingsReportedRemediation = 0;
        allItems.forEach((item) => {
            let hasRemediation = false;
            Object.keys(item).forEach((key) => {
                if (key.startsWith('remediation_')) {
                    createOrUpdateKey(remediationKeys, key);
                    hasRemediation = true;
                }
            });
            if (hasRemediation) {
                totalBuildingsReportedRemediation += 1;
            }
        });
        let remediationsOrdered = [];
        for (const item in remediationKeys) {
            remediationsOrdered.push([item, remediationKeys[item]]);
        }
        remediationsOrdered.sort(function(a, b) {
            return a[1] - b[1];
        });
        remediations = remediationsOrdered.reverse().slice(0,10).map(
            (item) => (
                `${item[0].replaceAll('remediation', '').replaceAll('_', ' ')} (${(100 / totalBuildingsReportedRemediation * remediationKeys[item[0]]).toFixed(0)}%)`
            )
        );
        console.log('totalBuildingsReportedRemediation: ',totalBuildingsReportedRemediation)
        console.log('>>remediations: ',remediations)
    }

    const calculateEligibility = (allItems) => {
        let total = 0;
        let counted = 0;
        allItems.forEach((item) => {
            let cladding = false;
            let other = false;
            let hasRemediation = false;
            Object.keys(item).forEach((key) => {
                if (key.startsWith('remediation_cladding')) {
                    cladding = true;
                    hasRemediation = true;
                }
                else if (key.startsWith('remediation_')) {
                    other = true;
                    hasRemediation = true;
                }

            });
            if (!hasRemediation) {
                return;
            }
            counted += 1;
            if (!other && cladding && item.height === '18+') {
                total += 1;
            }
        });
        eligibilityPercent = (total / counted * 100).toFixed(2);
        console.log('eligibilityPercent: ',eligibilityPercent)
    }

    const viveksCSVGenerator = (allData) => {
        return <p>{allData.filter((site)=> site.rating !== 'unnknown' && site.rating).map((site) => `${site.lat}, ${site.lng}, ${site.units}, ${site.rating}***`)}
        </p>
    }
    let demonstrations = [];
    firebase.firestore().collection('demonstrations').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => demonstrations.push(doc.data()))        
    });
    let articles = [];
    firebase.firestore().collection('articles').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => articles.push(doc.data()))        
    });

    console.log('demonstrations: ',demonstrations)
    console.log('articles: ',articles)

  return (<div>
    {/* <FirestoreCollection path="/sites" limit={270} where={ { field: 'verified', operator: '==', value: true }}> */}
    <FirestoreCollection path="/sites" limit={755}>
        
       {d => {
            if (d.isLoading) { return "Loading..."};

            d.value.forEach((item, i) => {
                const uid = d.ids[i];
                if (!item.verified) {
                    rowsToValidate.push(uid);
                }
            });

            d.value = shuffle(d.value);
            allItems = d.value;
            total = d.value.length;
            setUnits(allItems);
            setWW(allItems);
            setRA(allItems);
            calculateDates(allItems);
            calculateRatings(allItems);
            calculateDevelopers(allItems);
            calculatecostPassedToLeaseholder(allItems);
            calculateHeights(allItems);
            calculateRemediations(allItems);
            calculateAges(allItems);
            calculateEligibility(allItems);

            dataToProceess = d.value.map((item, i) => ({
                lng: item.lng, 
                lat: item.lat,
                cladding: item.remediation_cladding ? 'y' : 'n',
            }));

            dateAnimation = d.value.map((item, i) => ({
                lng: item.lng,
                lat: item.lat,
                date: item.date === "" ? "2099-05-01" : item.date,
            }));

            dateAnimation.sort(function(a, b) {
                return new Date(a.date) > new Date(b.date) ? 1 : -1;
            });

            window.dateAnimation = dateAnimation

            console.log('dateAnimation: ', dateAnimation)


            const nodes = dateAnimation.map((nodeData) => JSON.stringify({ lat: nodeData.lat, lng: nodeData.lng, date: nodeData.date }));
            console.log('nodes: ',nodes)

            return <ol className={"text-left"}>

                {/* { viveksCSVGenerator(allItems)}  */}
                { d.value.map((item, i) => 
                //item.rating === 'unnknown' ? null : `${item.lat}, ${item.lng}, ${item.units}, ${item.rating}, ${item.date}OOOO`
                <li key={i}>
                    {/* {item.suspicious ? 'SUSPICIOUS' : '' } */}
                    dev: {item.developer}, 
                    mng: {item.management}, 
                    {/* {item.lng}, {item.lat} */}
                    {item.comments} 
                    {/* {item.height} 
                    {item.age}  */}
                    {/* UID: {item.uid}. lng: { item.lng }, lat: { item.lat }. {item.verified ? "Verified" : "Not verified"} cost passed: {item.costspassedtoleaseholder} */}
                </li>
                ) 
                }
            </ol>
       }}

        </FirestoreCollection>
        <Button onClick={saveDataToDb} >Verify and save {total} records</Button>
    </div>);
}

export default React.memo(MslCmd)