import React, { useEffect } from 'react'
import { TwitterIcon } from "react-share";
import Modal from 'react-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import firebase from 'firebase';

const url = 'tinyurl.com/k7uy768b';

function DemoData(data) {
    const demo = data.demo;
    if (!demo.time_start) {
        demo.time_start = '12pm'
    }
    if (!demo.time_end) {
        demo.time_end = '12pm'
    }
    demo.date = 'Sat Jun 5th';
    const [open, setOpen] = React.useState(true);
    const selectTweet = () => 
        `📣I am going to the #EndOurCladdingScandal demonstration at ${demo.area_name}, on ${demo.date} (${demo.time_start} - ${demo.time_end}).${ demo.link ? `%0a%0a👉Details: ${demo.link}` : '' }%0a%0a📍Find your nearest @ claddingscandalmap.co.uk`;

    const tweet = selectTweet();
    const closeModal = () => {
      setOpen(false);
      data.onClose();
    }

    const trackTweets = () => {
      firebase.firestore().collection("quickstats").doc("totals").update({demotweets: firebase.firestore.FieldValue.increment(1)});
      closeModal();
    }
    const trackOpens = () => {
      firebase.firestore().collection("quickstats").doc("totals").update({demoviews: firebase.firestore.FieldValue.increment(1)});
    }
    trackOpens();
    
    return (
      <div className="modal" style={{ textAlign: 'center'}}>
        <Modal
          isOpen={true}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
        <FontAwesomeIcon style={{color: 'red', float: 'right', fontSize: '30px', cursor: 'pointer'}} icon={faTimes} onClick={closeModal}> Close</FontAwesomeIcon>
        <h2>Demonstration Details</h2>
        <hr />
        <p><strong>Developer: </strong><br />{demo.developer}</p>
        <p><strong>Date: </strong><br />{demo.date}, {demo.time_start} - {demo.time_end}</p>
        <p><strong>Event link: </strong><br /><a href={demo.link}>{demo.link}</a></p>
        <p><strong>Location:</strong><br />{demo.area_name}<br />
        <a href={`https://maps.google.com/?q=${demo.lat},${demo.lng}`}>Open in maps</a>
        </p>
        { demo.comments ? <p><strong>Comments:</strong><br />{demo.comments}</p> : null }
        <>
          <a onClick={()=>trackTweets()} target="_blank" href={"https://twitter.com/intent/tweet?text="+tweet.replaceAll('#','%23').replaceAll('&','%26')} >
            <Button style={{ fontWeight: '600', padding: '24px'}}><TwitterIcon size={40} round={true} style={{ margin: 'auto', display: 'block'}} /><br />TWEET THIS EVENT</Button>
          </a> 
        </>
        <br /><br />
        <p className="font-small">You accept full responsibility for your tweet.</p>
   
          </Modal>
        </div>
      );  
}

export default React.memo(DemoData)