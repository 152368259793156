import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import ExposedData from "./components/ExposedData";
import { Tweet } from 'react-twitter-widgets'

function Media() {

  return (
    <div className={'text-left'}>
      <h1>Media &amp; Press</h1>
      <p>Please contact us at <a href="mailto:claddingscandalmap@gmail.com">claddingscandalmap@gmail.com</a> for any press/media interests - we are always happy to talk!    
      </p>

      <hr />
      <h2>Press Articles</h2>

      <p><strong>Nationalworld.com<br/>"It’s not just a London problem": New mapping project reveals the extent of the cladding scandal</strong>
        <br />
        <a href="https://www.nationalworld.com/news/politics/its-not-just-a-london-problem-new-mapping-project-reveals-the-extent-of-the-cladding-scandal-3207490">
            View article
        </a>
      </p>

      {/* <p>
          <strong>Stephen Timms MP House of Commons Debate 27.04.2021 (and response from Matt Pennycock MP)</strong>
          <a href="https://www.theyworkforyou.com/debates/?id=2021-04-27c.264.2#g270.0">View article</a>
        </p>
        <p>Related Tweet &amp; video:</p>
 */}

      <p><strong>The Times on Sunday 9 May 2021<br/> EWS1 mess means we don’t know if our homes are safe</strong> - 
        EWS1 mess means we don’t know if our homes are safe
        <br />
        <a href="https://www.thetimes.co.uk/article/ews1-mess-means-we-dont-know-if-our-homes-are-safe-j5d9vqd3h">View Article</a>
        </p>

        <hr />

        <h2>Interest &amp; support on Twitter from key figures</h2>
        <Row>
            <Col>
                <Tweet tweetId="1387070592545959939"></Tweet>
            </Col>
            <Col>
                <Tweet tweetId="1392491832932814850"></Tweet>
            </Col>
        </Row>
        <Row>
            <Col>
                <Tweet tweetId="1391839596208660486"></Tweet>
            </Col>
            <Col></Col>
        </Row>
    
    </div>
  );

}

export default React.memo(Media);