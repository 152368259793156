import React, { useEffect } from 'react'
import { TwitterIcon } from "react-share";
import Modal from 'react-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import firebase from 'firebase';
import { MPS_NO_VOTE, MPS_SNP, MPS_LEDGES } from '../consts';

const url = 'tinyurl.com/k7uy768b';

const TWEETS = {
  default: [
    `, re. #FireSafetyBill where you voted to pass costs to leaseholders. Here's a map to show the impact this has on real people: ${url}`,
    `have a look at the homes of the leaseholders that you voted to pay for industry failure: ${url} #FireSafetyBill`,
    `you voted to pass costs to leaseholders on the #FireSafetyBill. Here's a map to show the impact this has on real people: ${url}`,
    `re. #FireSafetyBill, a map you might like: ${url} - you voted against leaseholders last time. Will you still vote for them to pay for builders' negligence?`,
    `here's a map relevant to the #FireSafetyBill where you voted down amendments to protect leaseholders. 1 pin=1+ homeowner forced to declare bankruptcy. ${url}`,
    `look at this map: ${url}. Something to think of when you next vote on #FireSafetyBill. 100,000s of lives ruined & the map has barely scratched the surface ${url}`,
    `have you seen this map? Each pin is 100s of people having to pay after your vote NOT to protect leaseholders from crippling costs and bankruptcy ${url}`,
  ],
  snp: `here's a map that you might like! It relates to the #FireSafetyBill. .@theSNP - this is evidently not an EVEL issue in the minds of .@ScotTories ${url}`,
  noVote: [
    `this map relates to the #FireSafetyBill showing families affected by your choice not to vote to protect leaseholders: ${url}. Will you vote to help this time?`,
    `how will you vote on the #FireBill? Abstention is cowardice. Or apathy. Or laziness. All are poor qualities for an elected leader. We are holding you accountable: ${url}`,
    `what is your excuse for not voting? Are you not obligated by those that elected you? You are accountable for the #FireBill. Vote for us, the taxpayer! ${url}`,
  ],
  thanks: [
    `thanks for your support of leaseholders that have are having their lives destroyed. Hope that others will see sense and help protect them from bankruptcy ${url}`,
    `your vote to protect leaseholders means everything to 100,000s of leaseholders nationwide, see for yourself: ${url} . Thank you!`,
    `100,000s of terrified leaseholders are dependant on your vote - thank you. We hope other MPs join you. See our project for visibility:https://www.facebook.com/claddingscandal.map.cond ${url} . Thank you!`,
    
  ]
}

function MPData(data) {
    const mp = data.mp;
    const mpName = `${mp[0]} ${mp[1]}`;
    const party = mp[6];
    let mpTwitter = (!mp[3] || mp[3] === 'N/A' || mp[3] === false || mp[3] === 'false') ? party === '@conservatives' : mp[3];
    if (!mpTwitter) {
      mpTwitter = party === 'Conservative' ? '@conservatives' : '';
    }
    const [open, setOpen] = React.useState(true);
    const selectTweet = () => {
      if (MPS_NO_VOTE.includes(mp)) {
        return TWEETS.noVote[Math.floor(Math.random() * TWEETS.noVote.length)];
      }
      if (MPS_SNP.includes(mp)) {
        return TWEETS.snp;
      }
      if (MPS_LEDGES.includes(mp)) {
        return TWEETS.thanks[Math.floor(Math.random() * TWEETS.thanks.length)];
      }
      return TWEETS.default[Math.floor(Math.random() * TWEETS.default.length)];
    };

    const tweet =`.${mpTwitter} ${mpName}, ${selectTweet()} #NeverVoteConservative #EndOurCladdingScandal`;
    const closeModal = () => {
      setOpen(false);
      data.onClose();
    }

    const trackTweets = () => {
      firebase.firestore().collection("quickstats").doc("totals").update({mptweets: firebase.firestore.FieldValue.increment(1)});
      closeModal();
    }
    const trackOpens = () => {
      firebase.firestore().collection("quickstats").doc("totals").update({mpviews: firebase.firestore.FieldValue.increment(1)});
    }
    trackOpens();
    
    return (
      <div className="modal" style={{ textAlign: 'center'}}>
        <Modal
          isOpen={true}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
        <FontAwesomeIcon style={{color: 'red', float: 'right', fontSize: '30px', cursor: 'pointer'}} icon={faTimes} onClick={closeModal}> Close</FontAwesomeIcon>

        <h2>{mpName}</h2>
        <hr />
        {/* {  mp[3] == 'N/A' ? <p><strong>Email:</strong> {mp[2]}</p> : null } */}
    <p><strong>Twitter:</strong> { mp[3] == 'N/A' ? `No account (using ${mpTwitter})` : <a href={'https://twitter.com/'+mpTwitter} title={mpName + `'s Twitter Account`}><TwitterIcon size={20} round={true} /> <strong>{mpTwitter}</strong></a> } </p>
        {/* <p><strong>Party:</strong> {mp[6]}</p> */}
        <p><strong>Constituency:</strong> {mp[7]}</p>
        <hr />

        <>
          <a onClick={()=>trackTweets()} target="_blank" href={"https://twitter.com/intent/tweet?text="+tweet.replaceAll('#','%23').replaceAll('&','%26')} >
            <Button style={{ fontWeight: '600', padding: '24px'}}><TwitterIcon size={40} round={true} style={{ margin: 'auto', display: 'block'}} /><br />CLICK TO TWEET <br />{mpName.toUpperCase()} <br />DIRECTLY!</Button>
          </a> 
        </>
      
  
      <CopyToClipboard text={tweet}
          onCopy={() => alert('Copied! Ready to paste. Sir, ye sir!')}
        >
          <>
            <p style={{ marginTop: '16px'}}>Click below to copy text:</p>
            <p style={{ border: '1px solid #e1e1e1', padding: '8px', cursor: 'pointer'}}><FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon> {tweet}</p>
          </>
        </CopyToClipboard>

        <p className="font-small">You accept full responsibility for your tweet. This data has been obtained from https://www.theyworkforyou.com/divisions/pw-2021-03-22-245-commons/mp/14131 17.4.21. Pin coordinates are generic/default centralised location derived from manual google map lookup on constit. name.</p>
   
          </Modal>
        </div>
      );  
}

export default React.memo(MPData)