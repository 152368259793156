import React from 'react'
import { Container, Button } from 'react-bootstrap';
import firebase from 'firebase';
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU");
Geocode.setLanguage("en");
Geocode.setRegion("en");
Geocode.setLocationType("ROOFTOP");

function Articles(data) {
    const [submitted, setSubmitted] = React.useState(false)

    const saveDataToDb = (newBill) => {
        firebase.firestore().collection("articles").add(newBill).then((ref) => { 
            setSubmitted(true);
        });
    }

    const validatePostCode = (postcode) => {
        const regExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
        return regExp.test( postcode );
    }
    
    const handleSubmit = (event) => {
        if (!event.target.postcode.value) {
            alert('Postcode is required.');
            return false;
        }
        // if (!validatePostCode(event.target.postcode.value )) {
        //     alert("Invalid postcode. Please be careful to get it right");
        //     return false;
        // }
        event.preventDefault();
        const newNHS = {
            verified: false,
            link: event.target.link.value,
            datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        let cancel = false;
        // Mark as not verified for approval
        newNHS.verified = false;
        Geocode.fromAddress(event.target.postcode.value).then(
            (response) => {
                console.log('response.results ',response.results)
                const { lat, lng } = response.results[0].geometry.location;
                newNHS.lat = lat;
                newNHS.lng = lng;
                saveDataToDb(newNHS);
            },
            (error) => {
                console.error(error);
                newNHS.error = true; 
                saveDataToDb(newNHS);
            }
        );
    }
  return <Container className="sidebar text-left" style={{ paddingTop: '40px', maxWidth: '600px', textAlign: 'left', margin: 0}}>
    <br />
    <h2 >News, Media &amp; Articles about the Cladding Scandal</h2>
    <hr />
    <br />
    <p>We're plotting Cladding Scandal articles on our map to help add an extra dimension to our pins.</p>
    <p>Please add any articles below - rough postcode for the area in which the article is set is fine.</p>
    
    <div>
    <br />

    { submitted ?
    <>
        <h1>Thanks!</h1>
        <p>Your contribution is vital to the success of this project.</p>
    </>
    :
    <form className="form" onSubmit={handleSubmit}>
         <hr />
        <fieldset><label htmlFor="postcode">Area Post Code <br />(this is not saved!)</label><input type="text" name="postcode" id="postcode"/></fieldset>        
        <fieldset><label htmlFor="link">Link of Article</label><input type="text" name="link" id="link"/></fieldset>

        <hr />

        <fieldset><label></label><Button type="submit">Submit</Button></fieldset>
    </form>
    }
</div>

</Container>
}

export default React.memo(Articles)