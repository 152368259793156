import React, { useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker, Rich, HeatmapLayer } from '@react-google-maps/api';
//import HeatmapLayer from "@react-google-maps/lib/visualization/HeatmapLayer"

import { TwitterIcon, FacebookIcon } from "react-share";
import MPData from './MPData';
import DemoData from './DemoData';
import Stats from './Stats';
import fontawesome from 'fontawesome-markers';
import { MPS, MPS_NO_VOTE, MPS_SNP, MPS_LEDGES } from '../consts';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faExclamationTriangle, faQuestionCircle, faThumbsUp, faPlayCircle, faCalendar, faMapMarkerAlt, faThermometerHalf, faFontAwesomeLogoFull, faFlag, faBullhorn, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import BodyText from './Body';
console.log(fontawesome)
const containerStyle = {
  // width: window.innerWidth < 800 ? `${window.innerWidth - 23}px` : '640px',
  // height: window.innerWidth < 600 ? ' 520px' : '640px',
  width: window.innerWidth < 800 ? `${window.innerWidth - 23}px` : '720px',
  height: window.innerWidth < 600 ? ' 520px' : '800px'
};
console.log(fontawesome)
const ICON_THUMB_DOWN = fontawesome.THUMBS_DOWN;
const ICON_FELICITY = fontawesome.QUESTION; //WARNING;
const ICON_NO_VOTE = fontawesome.QUESTION;
const ICON_LEDGES = fontawesome.THUMBS_UP;
const ICON_DEMO = fontawesome.BULLHORN;
const ICON_ARTICLE = fontawesome.NEWSPAPER_O;
const center = {
  lng: -2.2, //1.4701°
  lat: 53.8, // 51.50064
};
let index = 0;
let timer;

function Map(data) {
  const { isLoaded } = useJsApiLoader({
    id: 'f0169e318d805af4',
    mapIds: ['2d04df6ad4ba4c25'],
    libraries: ["visualization"],
    // mapId: 'f0169e318d805af4',
    googleMapsApiKey: "AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU",
  });
  let allMarkers = data.data.nodes.map((node) => JSON.parse(node));

  allMarkers = allMarkers.sort(function(a, b) {
      return new Date(a.date) > new Date(b.date) ? 1 : -1;
      //(a.color > b.color) ? 1 : -1;
  });
  // console.log('allMarkers -- ',allMarkers)

  let markersAnimated = [];
  let demos = data.data.demonstrations;
  let articles = [];// data.data.articles;

  const [map, setMap] = React.useState(null)
  const [showMP, setShowMP] = React.useState(null)
  const [showDemo, setShowDemo] = React.useState(null)
  const [toggleMPs, setToggleMPs] = React.useState(false)
  const [togglePins, setTogglePins] = React.useState(true)
  const [toggleCladding, setToggleCladding] = React.useState(false)
  const [toggleDemo, setToggleDemo] = React.useState(false)
  const [toggleArticles, setToggleArticles] = React.useState(true)
  const [toggleMPsNoVote, setToggleMPsNoVote] = React.useState(false)
  const [play, setPlay] = React.useState(false)
  const [markers, setMarkers] = React.useState(allMarkers);

  
  useEffect(()=> {
  }, [data.data, map]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const renderMPData = () => {
    return showMP ? <MPData mp={showMP} onClose={ () => setShowMP(null) }/> : null;
  }
  const renderDemoData = () => {
    return showDemo ? <DemoData demo={showDemo} onClose={ () => setShowDemo(null) }/> : null;
  }

  const renderMobileStatsPanel = () => window.innerWidth < 768 ? <Stats data={data} /> : null;
  
  const getIcon = (mp) => {
    if (mp[3] === '@FelicityBuchan') { return ICON_FELICITY; }
    if (MPS_NO_VOTE.includes(mp)) { return ICON_NO_VOTE; }
    if (MPS_LEDGES.includes(mp)) { return ICON_LEDGES; }
    return ICON_THUMB_DOWN;
  }

  const getScale = (mp) => {
    if (mp[3] === '@FelicityBuchan') { return 1; }
    if (MPS_NO_VOTE.includes(mp)) { return .6; }
    if (MPS_LEDGES.includes(mp)) { return .6; }
    return .3;
  }

  const renderDemos = () => toggleDemo ?
    demos.map((demo, i)=> demo.wave === 2 ? <Marker 
    key={i}
    onClick={ (e) => {
      setShowDemo(demo);
    }}
    position={ { 
      lat: parseFloat(demo.lat), 
      lng: parseFloat(demo.lng) 
    } }
    icon={{
      path: ICON_DEMO,
      scale: 0.6,
      strokeWeight: 1,
      strokeColor: 'black',
      strokeOpacity: 0.9,
      fillColor: '#ffc107', 
      fillOpacity:0.9,
      zIndex: 99
    }}
  ></Marker> : null) : null;

  const renderArticles= () => toggleArticles ?
    articles.map((article, i)=> <Marker 
    key={i}
    onClick={ (e) => {
      //setShowDemo(article);
      const win = window.open(article.link, '_blank');
      win.focus();
    }}
    position={ { 
      lat: parseFloat(article.lat), 
      lng: parseFloat(article.lng) 
    } }
    icon={{
      path: ICON_ARTICLE,
      scale: 0.5,
      strokeWeight: 1,
      strokeColor: 'black',
      strokeOpacity: 0.9,
      fillColor: '#fff', 
      fillOpacity:0.9,
      zIndex: 99
    }}
  ></Marker>) : null;

  const renderMPs = (mpList, toggle) => toggle ? 
    mpList.map((mp, i) => <Marker 
      key={i}
      onClick={ (e) => {
        setShowMP(mp);
      }}
      position={ { 
        lat: parseFloat(mp[4]), 
        lng: parseFloat(mp[5]) 
      } }
      icon={{
        path: getIcon(mp),
        scale: getScale(mp),
        strokeWeight: 1.5,
        strokeColor: 'white',
        strokeOpacity: 1,
        fillColor:  mp[6].toLowerCase().includes('con') ? 'blue'  : 'red', 
        fillOpacity:0.8,
        zIndex: 99
      }}
    ></Marker>) : null;
  
  const animate = () => {
    index = 0;
    if (timer) {
      clearTimeout(timer);
    }
    setMarkers([]);
    markersAnimated = [];
    setPlay(!play)
    runAnimate();
  }
  const runAnimate = () => {
    const timeout = index === 0 ? 15000 : index < 5 ? 200 : index < 15 ? 100 : 50;
    timer = setTimeout(() => {
      if (markersAnimated.length !== allMarkers.length) {
        markersAnimated = allMarkers.slice(0, index);
          setMarkers(markersAnimated);
          index ++;
          runAnimate();
      }
    }, timeout);
  }

  let lastDate;
  const renderMapDate = () => {
    let dateString;
    if ( !markers[markers.length -1] || markers[markers.length -1].date === 'Unknown') {
      // lastDate = 'Unknown';
      return lastDate
    }

    if (markers[markers.length -1].date.startsWith('2099') ) {
      lastDate = 'Unknown';
      return lastDate
    }

    dateString = new Date(markers[markers.length -1].date).toLocaleString('default', { month: 'long', year: 'numeric' });
    if (dateString.toLowerCase().startsWith('invalid')) {
      //return lastDate;
    }
    if (dateString) {
          lastDate = dateString;
    }
    return dateString ?? lastDate;
  }
  return isLoaded && data.data ? (
    <>
      {renderMPData()}
      {renderDemoData()}
      
      <GoogleMap
      id={'f0169e318d805af4'}
      mapId={'f0169e318d805af4'}
      mapTypeId={['f0169e318d805af4']}
        className="map-view"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={6}
        onUnmount={onUnmount}
        gestureHandling='none'
        options={{ mapId: "f0169e318d805af4", minZoom: 6, maxZoom: 13, streetViewControl: false, 
          mapTypeControl: false
      }}
      >
      { togglePins ? <>
        {/* { renderMPs(MPS_NO_VOTE, toggleMPsNoVote) }
        { renderMPs(MPS_SNP, toggleMPsSNP) }
        { renderMPs(MPS_LEDGES, toggleMPsLedges) } */}
          { !toggleCladding ? 
            markers.filter((site)=> site.lat && site.lng).map((site, i) => <Marker 
              key={i}
              position={ { lat: site.lat, lng: site.lng } }
              //onClick={(item)=>alert(JSON.stringify(item))}
            ></Marker>)
            :
            markers.filter((site)=> site.lat && site.lng && site.cladding === 'y').map((site, i) => <Marker 
              key={i}
              position={ { lat: site.lat, lng: site.lng } }
            ></Marker>)
          }
        </>
        :
        <HeatmapLayer 
        options={{radius:50, opacity: 0.65, maxIntensity: 8}}
          data={[new window.google.maps.LatLng(53.8, -2.2)] }
            data={!toggleCladding ? 
              markers.filter((site)=> site.lat && site.lng).map((site) => new window.google.maps.LatLng(site.lat, site.lng))
              : 
              markers.filter((site)=> site.lat && site.lng && site.cladding === 'y').map((site) => new window.google.maps.LatLng(site.lat, site.lng))             
            }
          />
      }
      <>
        { renderDemos() }
        {renderArticles()}
        { renderMPs([...MPS, ...MPS_NO_VOTE], toggleMPs) }
      </>
      </GoogleMap> 
      
      <div className="map-filters">        
        <Button style={{marginRight: '8px'}} className="map-btn-mps" onClick={()=> setTogglePins(!togglePins) }>
          <FontAwesomeIcon icon={ togglePins ? faMapMarkerAlt : faThermometerHalf } /> View: { togglePins ? ' PINS' : ' HEAT' } 
        </Button>&nbsp;
        <Button className="map-btn-mps" onClick={()=> setToggleCladding(!toggleCladding) }>
          <FontAwesomeIcon icon={ faExclamationTriangle } /> Has Cladding: { !toggleCladding ? ' OFF' : ' ON' }
        </Button>&nbsp;
        <Button style={{marginRight: '8px'}} className="map-btn-mps" onClick={()=> setToggleDemo(!toggleDemo) }>
          <FontAwesomeIcon icon={ faBullhorn } />  Protests: { !toggleDemo ? ' OFF' : ' ON' }
        </Button>
        {/* <Button className="map-btn-mps" onClick={()=> setToggleMPs(!toggleMPs) }>
          <FontAwesomeIcon icon={ faThumbsDown } />  MPs: { !toggleMPs ? ' OFF' : ' ON' }
        </Button> */}
        {/* <Button className="map-btn-mps" onClick={()=> setToggleArticles(!toggleArticles) }>
          <FontAwesomeIcon icon={ faNewspaper } />  Articles: { !toggleArticles ? ' OFF' : ' ON' }
        </Button> */}
        {/* <Button className="map-btn-mps" onClick={()=> setToggleMPsNoVote(!toggleMPsNoVote) }>
          { toggleMPsNoVote ? ' Hide' : ' Show' } MPs "no vote" <FontAwesomeIcon icon={ faQuestionCircle } /> 
        </Button> */}

        {/* <Button className="map-btn-mps" onClick={()=> animate() }>
          { play ? ' Play' : ' Stop' } Animate Certificate Dates <FontAwesomeIcon icon={ faPlayCircle } /> 
        </Button> */}

        {/* <div id="map-date">
          <FontAwesomeIcon icon={ faMapMarkerAlt } /> = 1 affected building<br />
          <FontAwesomeIcon icon={ faCalendar } /> { renderMapDate() }
        </div> */}
      </div>

      <div className="content-block text-left" style={{ paddingRight: window.innerWidth < 600 ? '0' : '8px', marginTop: '-16px' }}>
        {renderMobileStatsPanel()}
        <BodyText />
      </div>
    </>
  ) : <></>
}

export default React.memo(Map)