import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FirestoreProvider, FirestoreCollection, FirestoreDocument } from "@react-firebase/firestore";
import firebase from 'firebase';
import ExposedData from "./components/ExposedData";

function Faq() {

  return (
    <div className={'text-left'}>
        <h1> Frequently Asked Questions (FAQ)</h1>
        <ol>
            <li><a href="#0.1_q1" title="What happens when I submit my building details?">What happens when I submit my building details?</a></li>
            <li><a href="#0.1_q2" title="How accurate is your data?">How accurate is your data?</a></li>
            <li><a href="#0.1_q3" title="What do you hope to achieve?">What do you hope to achieve?</a></li>
            <li><a href="#0.1_q4" title="Why aren’t you working with the official campaign groups?">Why aren’t you working with the official campaign groups?</a></li>
            <li><a href="#0.1_q5" title="Is it safe to have my building details on your map?">Is it safe to have my building details on your map?</a></li>
            <li><a href="#0.1_q6" title="Won’t being on this map affect my apartment value?">Won’t being on this map affect my apartment value?</a></li>
            <li><a href="#0.1_q7" title="Will it make it harder to get insurance?">Will it make it harder to get insurance?</a></li>
            <li><a href="#0.1_q8" title="Are you planning to sell the data at any point?">Are you planning to sell the data at any point?</a></li>
            <li><a href="#0.1_q9" title="Have you got a privacy policy?">Have you got a privacy policy?</a></li>
        </ol>

        <h2><a id="1_q1" />What happens when I submit my building details?</h2>
        <p>Your postcode is converted to GPS coordinates and plotted on our map. 
        This happens inside your browser; your postcode is never received by, or stored on, our computers.
        Data about building height, EWS1 rating, waking watch status and developer is completely anonymous. 
        It gets added to the rest of the data we have, so we can start to form a national picture. 
        Our data cannot be tracked back to an individual building and we are fully GDPR compliant. 
        We do not use any cookies or share your data. Please see our <a href="https://claddingscandalmap.co.uk/privacy" target="_blank">Privacy policy</a> for more details.</p>

        <h2><a id="0.1_q2" />How accurate is your data?</h2>
        <p>Just like Wikipedia, we rely on user-generated content, so we accept it may not be 100% accurate. 
        To our knowledge there is no other website that attempts to make this information publicly available, and we doubt the government or building industry is going to create one. 
        We may not be perfect, but we are all there is.</p>

        <h2><a id="0.1_q3" />What do you hope to achieve?</h2>
        <p>We want to raise awareness of the building safety scandal so that people across the UK hear about it. 
        We are worried people are believing the government lies when they claim they have the problem under control or that it only affects a small number of buildings. 
        The map and the statistics we create form an easy visualisation that shows the scale of the problem. 
        Some journalists are now writing about the scandal after our map made them realise it was not just a London issue. 
        We think more media scrutiny of the government can only be a good thing.</p>

        <h2><a id="0.1_q4" />Why aren’t you working with the official campaign groups?</h2>
        <p>We have had some positive initial discussions with some of the other campaign groups &amp; received some constructive feedback, which we have taken onboard and have already started incorporating. 
          We now continue to keep dialogue open, while we assess potential of working together in the future, we continue to do what we can to help the shared goal of #EndOurCladdingScandal</p>

        <h2><a id="0.1_q5" />Is it safe to have my building details on your map?</h2>
        <p>Individual buildings cannot be identified, as we have disabled the zoom facility from getting too close. 
        We do not collect building names, so we cannot locate a building within our database. 
        The Fire Industry Association is already publishing full EWS1 certificates on their website, which reveal far more information than can be accessed from our map.</p>

        <h2><a id="0.1_q6" />Won’t being on this map affect my apartment value?</h2>
        <p>No, failing an EWS1 assessment is what damages your apartments value. 
        Such information would need to be disclosed to any potential buyers, so keeping your B1 rated building off our map will not make any difference to the value.</p>

        <h2><a id="0.1_q7" />Will it make it harder to get insurance?</h2>
        <p>The UK insurance market is the 4th largest in the world and the largest in Europe. We very much doubt they are relying on our website as a source of information. 
        If insurers have formed the opinion your apartment is an insurance risk, they will have reached that conclusion without any help from us.</p>

        <h2><a id="0.1_q8" />Are you planning to sell the data at any point?</h2>
        <p>Absolutely not. Most of the data we collect is already available from other sources, so our data has little commercial value (if any). 
        The real value of this data is to try and build an objective, publicly available view of the size/scale and severity of this crisis.</p>

        <h2><a id="0.1_q9" />Have you got a privacy policy?</h2>
        <p>Yes: Please <a href="https://claddingscandalmap.co.uk/privacy" target="_blank">see it here</a></p>

    </div>
  );

}

export default React.memo(Faq);