import React from 'react'
import { Container, Button } from 'react-bootstrap';
import firebase from 'firebase';
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU");
Geocode.setLanguage("en");
Geocode.setRegion("en");
Geocode.setLocationType("ROOFTOP");

function Protests(data) {
    const [submitted, setSubmitted] = React.useState(false)

    const saveDataToDb = (newBill) => {
        firebase.firestore().collection("demonstrations").add(newBill).then((ref) => { 
            setSubmitted(true);
        });
    }

    const validatePostCode = (postcode) => {
        const regExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
        return regExp.test( postcode );
    }
    
    const handleSubmit = (event) => {
        if (!event.target.postcode.value) {
            alert('Postcode is required.');
            return false;
        }
        // if (!validatePostCode(event.target.postcode.value )) {
        //     alert("Invalid postcode. Please be careful to get it right");
        //     return false;
        // }
        event.preventDefault();
        const newNHS = {
            area_name: event.target.area_name.value,
            developer: event.target.developer.value,

            date: event.target.date.value,
            time_start: event.target.time_start.value,
            time_end: event.target.time_end.value,
            verified: false,
            comments: event.target.comments.value,
            link: event.target.link.value,
            datecreated: firebase.firestore.FieldValue.serverTimestamp(),
            wave: 2,
        };
        let cancel = false;
        // Mark as not verified for approval
        newNHS.verified = false;
        Geocode.fromAddress(event.target.postcode.value).then(
            (response) => {
                console.log('response.results ',response.results)
                const { lat, lng } = response.results[0].geometry.location;
                newNHS.lat = lat;
                newNHS.lng = lng;
                newNHS.wave = 2;
                saveDataToDb(newNHS);
            },
            (error) => {
                console.error(error);
                newNHS.error = true; 
                saveDataToDb(newNHS);
            }
        );
    }
  return <Container className="sidebar text-left" style={{ paddingTop: '40px', maxWidth: '600px', textAlign: 'left', margin: 0}}>
    <br />
    <h2 >Peaceful Protest Plotter</h2>
    <hr />
    <br />
    <p>Add a location for a peaceful protest</p>
    
    <div>
    <br />

    { submitted ?
    <>
        <h1>Thanks!</h1>
        <p>Your contribution is vital to the success of this project.</p>
    </>
    :
    <form className="form" onSubmit={handleSubmit}>
        <fieldset><label htmlFor="postcode">Demonstration Post Code <br />(this is not saved!)</label><input type="text" name="postcode" id="postcode"/></fieldset>        
        <fieldset><label htmlFor="area_name">Area Name</label><input type="text" name="area_name" id="area_name"/></fieldset>
        <fieldset><label htmlFor="developer">Developer</label><input type="text" name="developer" id="developer"/></fieldset>
        
        <fieldset><label htmlFor="date">Date of demonstration</label>
            <input id="date" name="date" type="date"></input>
        </fieldset>
        <fieldset><label htmlFor="time_start">Start Time</label>
            <input id="time_start" name="time_start" type="text"></input>
        </fieldset>
        <fieldset><label htmlFor="time_end">End Time</label>
            <input id="time_end" name="time_end" type="text"></input>
        </fieldset>
        <fieldset><label htmlFor="link">Link (eg Facebook event)</label><input type="text" name="link" id="link"/></fieldset>

        <hr />

        <p>DO NOT ENTER PERSONAL DATA INTO THE COMMENTS</p>
        <p>By submitting, you agree to our Privacy policy, and confirm that you are not submitting personal data; postcode is that of the building, and not a person.</p>

        <fieldset><label htmlFor="comments">Comments/Extra Info</label>
            <textarea name="comments"></textarea>
        </fieldset>
        <fieldset><label></label><Button type="submit">Submit</Button></fieldset>
    </form>
    }
</div>

</Container>
}

export default React.memo(Protests)