import React from 'react'

function Logo() {
  
  return <div className="logo">
      <img width={80} src={"/logo.svg"}/>
      <h1>BUILDING<br />SAFETY<br />MAP</h1>
  </div>;
}

export default React.memo(Logo)


export function NhsLogo(props) {
  return (
    <svg
      width={370.61}
      height={150}
      viewBox="0 0 370.61 150"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#005eb8" d="M0 0h370.61v150H0z" />
      <g fill="#fff">
        <path d="M36.225 14.475h40.313l24.712 83.737h.375l16.95-83.737h30.45l-25.613 120.75H83.288l-25.275-83.55h-.338l-16.762 83.55h-30.45zM160.912 14.475h32.363l-9.525 46.2H222l9.525-46.2h32.363L238.8 135.225h-32.55l10.725-51.712h-38.063l-10.725 51.712h-32.325zM348.975 41.813a60.675 60.675 0 00-26.475-5.55c-12.788 0-23.175 1.912-23.175 11.587 0 17.138 46.875 10.725 46.875 47.4 0 33.375-30.975 42.038-58.988 42.038a135 135 0 01-37.5-6.226l7.5-24.562c6.413 4.163 19.2 6.938 29.738 6.938s25.762-1.913 25.762-14.363c0-19.387-46.875-12.113-46.875-46.2 0-31.125 27.338-40.5 53.813-40.5 15 0 28.875 1.575 37.012 5.362z" />
      </g>
    </svg>
  )
}
