import React, { useEffect } from 'react'
import { TwitterIcon } from "react-share";
import { LOCALES } from '../Locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useAppState } from '../Store';


function BodyText(data) {
    const {state} = useAppState();
    const lang = state.lang ?? 'en';
    const locale = LOCALES[lang];
  return <>
    <br />


    { lang != 'en' && <div class="warning" style={{marginBottom: '32px'}}>
    <h2><FontAwesomeIcon icon={faExclamationTriangle} /> {locale.body.int.title} <FontAwesomeIcon icon={faExclamationTriangle} /></h2>
    <p>{locale.body.int.body}</p>
    </div>}
    

    <div style={ { textAlign: lang === 'ar' ?  'right' : 'left'} }>
      <h2>{locale.body.who.title}</h2>
      <p>{locale.body.who.body}</p>
      <br />

      <h2>{locale.body.what.title}</h2>
      {locale.body.what.body.split('\n').map((item) => <p>{item}</p>)}
      <br />

      <h2>{locale.body.press.title}</h2>
      <p>{locale.body.press.body}</p>
      <br />
    </div>

{/* 
      <br />
      <h2>WHO ARE WE?</h2>
      <p>A community of peoplease holders) affected by the cladding scandal. We are not in the industry or in the politics.</p>
      
      <br />
      <h2>WHAT IS THE CLADDING SCANDAL (AND WHO IS AFFECTED)?</h2>
      <p>The Grenfell Tower disaster in June 2017, which claimed 72 lives, triggered calls for changes to UK building regulations 
        and the strengthening of fire safety measures.</p>
      <p>The fires revealed that large numbers of buildings had been clad in dangerously combustible materials. Additionally,
        many buildings have been found to be non-compliant with other fire-safety building requirements which are intended 
        to prevent fires from spreading horizontally and vertically into neighbouring flats.</p>
        <p>As well as these buildings posing an immediate fire risk to residents, flat owners find themselves facing extensive
           and costly remedial work, rocketing buildings insurance premiums, and the possibility of 'waking watches': paying people 
           to patrol buildings 24/7 to check for fires.</p>
        <p>Mortgage lenders, struggling to quantify the fire-risks posed by different buildings, have ceased to lend money for the
           purchase of many properties unless their owners can prove the building is safe, usually by way of an 'EWS1' form.</p>
        <p>As of February 2021, the UK government had pledged over £5 billion towards remediation works, but extensive costs were 
          still falling on the shoulders of individual leaseholders of flats who had unwittingly purchased unlawfully constructed homes.</p>
        <p>Our aim is to connect all those affected by the ongoing cladding issue, and to generate visibility. Zoom is locked to a certain height, and no site data (postcode, title, etc) is used. 
    We do not take personal data, just a summary for sites affected. We intend on using this data to apply pressure and improve the rights of the leaseholder in doing so.</p>

        <p><a href="/faq" title="FAQ">Find out more on our FAQ</a></p> */}

      <br />

      <p><a href="https://twitter.com/claddingscanda1" title="CladdingCsnadalMap Twitter Account"><TwitterIcon size={20} round={true} /> <strong>@claddingscanda1</strong> Twitter Account</a></p>

      <p>Email us at: <a href="mailto:claddingscandalmap@gmail.com">claddingscandalmap@gmail.com</a>.</p>

      <p><a href="/faq" title="FAQ">Find out more on our FAQ</a></p>
    </>;
}

export default React.memo(BodyText);