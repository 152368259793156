import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FirestoreProvider, FirestoreCollection, FirestoreDocument } from "@react-firebase/firestore";
import firebase from 'firebase';
import ExposedData from "./components/ExposedData";

function Privacy() {

  return (
    <div className={'text-left'}>
      <h1>Disclaimer</h1>
      <p>While reasonable endeavour is taken to ensure the accuracy of the information and aggregated data within this website and output 
      by this website, all stats are for illustrative purposes only and are derived from unvetted, unofficial user-submitted data 
      provided in good faith and not in confidence. The Building Safety Map does not make any representations or warranties about 
      its accuracy, completeness, reliability, or suitability for any particular purpose and disclaim all responsibility and all liability
      for all expenses, losses, damages and costs which might be incurred as a result of the data being inaccurate or incomplete in any
        way and for any reason.</p>

      <h2>Privacy Policy</h2>
      <p>What is the purpose of this privacy notice?</p>
      <p>The purpose of this privacy notice is to inform you as how The Building Safety Map  collect, uses, disclose, delete, and 
        look after the information that you give The Building Safety Map when you use this website. The Building Safety Map  
        is committed to ensuring that your privacy is protected. This website is not intended for children and we do not knowingly
         collect data relating to children, it is not The Building Safety Map intention to ask you to provide information by which
          you can be identified when using this website, you can be assured that the information you give The Building Safety Map 
          will only be used in accordance with this privacy statement.</p>
      <p>The Building Safety Map may change this policy from time to time by updating this page. You should check this page 
        from time to time to ensure that you are happy with any changes. This policy is effective from 9th April 2021.</p>
      
      <h2>Who we are</h2>
      <p>The Building Safety Map consists of volunteers that are affected leaseholders. They are not in the house/construction/political industries</p>
      
      <h2>What we collect</h2>
      
      <p>The Building Safety Map is GDPR compliant by design.  The Building Safety Map targets group of developments affected by the Cladding
         / ESW1 scandal rather than individuals. </p>
      <p>Although, we collect Post Codes, it is for the development, The Building Safety Map does not know the identity of the 
        people at those addresses, thus, it is not processing personal data, and the GDPR rules will not apply. The information 
        you give The Building Safety Map is in most circumstances then anonymised, do not contain personal data and Post Codes 
        are not stored.</p>
        <p>The Building Safety Map does not collect any Special Categories of Personal Data about you (this includes details 
          about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, 
          trade union membership, information about your health and genetic and biometric data). Nor does The Cladding Scandal
           Map collect any information about criminal convictions and offences.</p>
      
      <h2>What we do with the information we gather</h2>
      
      {/* <p>The Building Safety Map will use the information in the following circumstances xxxxxx</p> */}
      <p>We also use the information to disclose aggregated data such as statistical data for any purpose. 
        Aggregated data is derived from the information you give The Building Safety Map but is not considered personal 
        data in law as this data does not directly or indirectly reveal your identity. For example, The Building Safety Map may
         aggregate total buildings with wakingwatch reported to calculate the percentage of waking watch sites. </p>
      <p>As the GDPR rules do not apply, The Building Safety Map does  not need legitimate interest, consent or any legal basis 
        for processing data as the long/lat coordinate data we use for targeting is not subject to GDPR.</p>

    <h2>What Data Do We Expose?</h2>
    <ExposedData />
  
  <h2>Information Security</h2>
  <p>The Building Safety Map  are committed to ensuring that the information we collect is secure. In order to prevent unauthorised
   access or disclosure we have put in place suitable security measures and procedures to safeguard and secure the information we
    collect online.</p>

  <h2>How we use cookies</h2>
  <p>We don’t use any cookies.</p>

  <h2>Links to Other Websites</h2>
  <p>The Building Safety Map website may contain links to other websites or applications that are not operated by us. This  privacy notice does not cover how those third-party websites or application processes personal data. For example, we link to our profiles on a number of social media platforms such as Twitter, LinkedIn and  Facebook. If you click on a third-party link, you will be directed to that third party's website or application.</p>
  <p>The Building Safety Map have no control over and assume no responsibility for the content, privacy notices, policies or practices of any third-party website or application. Third-party websites or applications may implement their own tracking, cookie technologies and other mechanisms to serve their users, the Building Safety Map strongly encourage you to review and read  the Privacy Notice of every website or application you visit.</p>

  <h2>Information Retention </h2>
  <p>The Building Safety Map will only retain the information you give The Building Safety Map for as long as necessary 
  to fulfil the purposes we collected it for, including for the purposes of satisfying any legal requirements. To determine
   the appropriate retention period for the information, The Building Safety Map considers the amount and nature of the 
   information, the potential risk of harm from unauthorised use or disclosure, the purposes for which we process the 
   information you give The Building Safety Map and whether The Building Safety Map can achieve those purposes through 
   other means, and legal if applicable. </p>

  <p>In most circumstances The Building Safety Map will anonymise the information (so that it can no longer be associated with the 
    development) for statistical purposes in which case we may use this information indefinitely. </p>

  <h2>Purpose of the information</h2>
  <p>To raise awareness and visualise the scale of the scandal, and the volume of buildings impacted.</p>
  <p>The Building Safety Map  do not have a commercial gain in this project, The Building Safety Map will not sell, 
    distribute or lease the information you have provided to third parties unless we are required by law to do so. </p>

  <h2>Change of Purpose of the information</h2>
  <p>The Building Safety Map  will only use the information you have provided for the purposes for which we collected it,
    unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original 
    purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose,
      please email claddingscandalmap@gmail.com. If we need to use the information provided for an unrelated purpose, we will make 
      it visible on the website, as we are not collecting personal information, The Building Safety Map will not be able to notify
      you directly. </p>
  <h2>QUERIES, REQUESTS OR CONCERNS </h2>
  <p>Please use our gmail claddingscandalmap@gmail.com for queries or complaints in relation to this policy or bug 
    reports or offers of technical help. All other queries through Twitter @claddingscandal1</p>

    <h2>Content</h2>
    <p>Where does the content come from?</p>
    <p>Most of the content you’ll see on this site; the Contact Information and the facts and figures about MPs – is imported from elsewhere. The Buildings location, EWS ratings and other related data is user contributed.</p>
    <p>MP Contact Information, for example, come from the official Parliamentary website. MPs’ Constituencies and Voting data also come from parliamentary data sources ( https://votes.parliament.uk/Votes/Lords/Division/2487 ) and some of the voting data from TheyWorkForYou website ( https://www.theyworkforyou.com/divisions/pw-2021-03-22-245-commons/mp/14131 ).</p>
    
    {/* <p>We do not hold any personal data for anyone using our site. You can contact us request@claddingscandalmap.co.uk to find out what data if any we hold for you.</p>
    <p>While we do not hold any personal data for anyone using out site, if you have specific reasons to believe we do hold any personal data for you 
      - the General Data Protection Regulation gives you the right to object to our processing of your personal information and to 
      ask us to stop processing it. However, it also gives us the right to continue to process it if we can demonstrate 
      compelling legitimate grounds for the processing that override your interests, rights and freedoms. 
      To exercise your right to object, you can contact us at request@claddingscandalmap.co.uk, giving specific reasons 
      why you are objecting to the processing of your personal data &amp; what specific personal data you are objecting about.
       These reasons should be based upon your particular situation.</p> */}
       <p>The Building Safety Map disclosed Data generated from your activity as an MP on external websites; If you believe Content about you in our website is inaccurate or incomplete, you can ask for rectification. 
Please note the request must come from your official MP email, in order to verify your identity before responding to such requests.</p>
    
    
    </div>
  );

}

export default React.memo(Privacy);