import React from 'react'
import { Container, Button } from 'react-bootstrap';
import firebase from 'firebase';
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDLhoS9LNPwVmBmyKIQx8eQFGMc84G-VPU");
Geocode.setLanguage("en");
Geocode.setRegion("en");
Geocode.setLocationType("ROOFTOP");

function Bills(data) {
    const [submitted, setSubmitted] = React.useState(false)

    const saveDataToDb = (newBill) => {
        firebase.firestore().collection("bills").add(newBill).then((ref) => { 
            setSubmitted(true);
        });
    }

    const validatePostCode = (postcode) => {
        const regExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
        return regExp.test( postcode );
    }
    
    const handleSubmit = (event) => {
        if (!event.target.postcode.value) {
            alert('Postcode is required.');
            return false;
        }
        if (!validatePostCode(event.target.postcode.value )) {
            alert("Invalid postcode. Please be careful to get it right");
            return false;
        }
        event.preventDefault();
        const newNHS = {
            developer: event.target.developer.value,
            amount: event.target.amount.value,
            percentage: event.target.percentage.value,
            date_issued: event.target.date_issued.value,
            date_due: event.target.date_due.value,
            help_to_buy: event.target.help_to_buy.value,
            shared: event.target.shared.value,
            reductions_bsf: event.target.reductions_bsf.value,
            reductions_other: event.target.reductions_other.value,
            reductions_developer: event.target.reductions_developer.value,
            height: event.target.height.value,
            verified: false,
            comments: event.target.comments.value,
            datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        let cancel = false;
        // Mark as not verified for approval
        newNHS.verified = false;
        Geocode.fromAddress(event.target.postcode.value).then(
            (response) => {
                console.log('response.results ',response.results)
                const { lat, lng } = response.results[0].geometry.location;
                newNHS.lat = lat;
                newNHS.lng = lng;
                saveDataToDb(newNHS);
            },
            (error) => {
                console.error(error);
                newNHS.error = true; 
                saveDataToDb(newNHS);
            }
        );
    }
  return <Container className="sidebar text-left" style={{ paddingTop: '40px', maxWidth: '600px', textAlign: 'left', margin: 0}}>
    <br />
    <h2 >EWS1 Bills</h2>
    <hr />
    <br />
    <p>This form is to help visualise costing and timeframes around EWS1 remediation across the country.</p>
    
    <div>
    <br />

    { submitted ?
    <>
        <h1>Thanks!</h1>
        <p>Your contribution is vital to the success of this project.</p>
    </>
    :
    <form className="form" onSubmit={handleSubmit}>
        <fieldset><label htmlFor="postcode">Building Post Code <br />(this is not saved!)</label><input type="text" name="postcode" id="postcode"/></fieldset>        
        <fieldset><label htmlFor="developer">Developer</label><input type="text" name="developer" id="developer"/></fieldset>
        <fieldset><label htmlFor="height">Building height</label>
            <select name="height">
            <option value="under-11">Under 11M</option>
            <option value="11-18">11M-18M</option>
            <option value="18+">18M+</option>
            </select>
        </fieldset>
        <fieldset><label htmlFor="amount">Amount Due<br/>(Total remediation PER FLAT)</label><input type="text" name="amount" id="amount"/></fieldset>
        <fieldset><label htmlFor="reductions_bsf">Cash awarded from Building Safety Fund (BSF)</label><input type="text" name="reductions_bsf" id="reductions_bsf"/></fieldset>
        <fieldset><label htmlFor="reductions_developer">Cash awarded from developer</label><input type="text" name="reductions_developer" id="reductions_developer"/></fieldset>
        <fieldset><label htmlFor="reductions_other">Cash awarded from other</label><input type="text" name="reductions_other" id="reductions_other"/></fieldset>
        <fieldset><label htmlFor="percentage">
            Roughly what % of the LATEST property valuation (before EWS1 £0!)</label>
        <select name="percentage">
            <option value="na">N/A</option>
            <option value="0">0 %</option>
            <option value="10">10%</option>
            <option value="20">20%</option>
            <option value="30">30%</option>
            <option value="40">40%</option>
            <option value="50">50%</option>
            <option value="60">60%</option>
            <option value="70">70%</option>
            <option value="80">80%</option>
            <option value="90">90%</option>
            <option value="100">100%</option>
            <option value="100+">More than 100%</option>
            </select>
        </fieldset>
        <fieldset><label htmlFor="shared">
            Are you a shared owner?</label>
        <select name="shared">
             <option value="no">No</option>
            <option value="yes">Yes</option>
           
            </select>
        </fieldset>
        <fieldset><label htmlFor="help_to_buy">
            Are you "Help To Buy"?</label>
        <select name="help_to_buy">
            <option value="no">No</option>
            <option value="yes">Yes</option>

            </select>
        </fieldset>
        <fieldset><label htmlFor="date_issued">Date of bill issued</label>
            <input id="date_issued" name="date_issued" type="date"></input>
        </fieldset>
        <fieldset><label htmlFor="date_due">Date payment due</label>
            <input id="date_due" name="date_due" type="date"></input>
        </fieldset>
        <hr />

        <p>DO NOT ENTER PERSONAL DATA INTO THE COMMENTS</p>
        <p>By submitting, you agree to our Privacy policy, and confirm that you are not submitting personal data; postcode is that of the building, and not a person.</p>

        <fieldset><label htmlFor="comments">Comments/Summary of issue(s)</label>
            <textarea name="comments"></textarea>
        </fieldset>
        <fieldset><label></label><Button type="submit">Submit</Button></fieldset>
    </form>
    }
</div>

</Container>
}

export default React.memo(Bills)