import React, { useState, useEffect, useStore, useContext, store } from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterIcon,
  } from "react-share";
import { Button } from 'react-bootstrap';
import Logo from './Logo';
import { LOCALES } from "../Locales";
import { useAppState } from '../Store';

const URL = 'https://claddingscandalmap.co.uk';

function Share() {
  const {state, dispatch} = useAppState();
  const setLang = (lang) => {
    dispatch({ type: 'SET_LANGUAGE', payload: lang });
  }

  const lang = state.lang ?? 'en';
  const locale = LOCALES[lang];
  
  return <div className="share">
    <p>
    <a href="/" style={{ color: 'black'}}><div className="main-logo"><Logo /></div></a>
    <span className="share-wrap">
      {/* <strong>{locale.buttons.share} </strong>  */}

      {/* <FacebookShareButton url={URL} hashtag={'#cladding'} 
        quote={"The Building Safety Map. A non-profit, community-driven project to illustrate the scale of the cladding scandal."}>
          <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={URL} via={URL} hashtag={'#cladding'} style={{ marginRight: '32px'}}
        title={"The Building Safety Map. A non-profit, community-driven project to illustrate the scale of the cladding scandal."}>
          <TwitterIcon size={32} round={true} />
      </TwitterShareButton> */}

      {/* <LinkedinShareButton url={URL} source={URL} 
              title={"The Building Safety Map. A non-profit, community-driven project to illustrate the scale of the cladding scandal."}>
          <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>

      <WhatsappShareButton url={URL} 
              title={"The Building Safety Map. A non-profit, community-driven project to illustrate the scale of the cladding scandal."}>
          <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton> */}
      </span>

      <a href="/#report" style={{ marginLeft: '120px'}}><Button id="btn-add-building">
        + {locale.buttons.add}</Button></a>

      {/* { lang === 'en' ? 
        <a href="/nhs">
          <Button style={{margin: '0px 32px 0 0px', padding: '16px',  fontWeight: 'bold', fontSize: '20px', border: '0'}}>🌈 &nbsp;NHS Survey</Button>
        </a> : null } */}

      <div className="languages">
      <Button className="lang-btn" onClick={()=> setLang('en')}>🇬🇧</Button>
      <Button className="lang-btn" onClick={()=> setLang('zh')}>🇨🇳</Button>
      <Button className="lang-btn" onClick={()=> setLang('ru')}>🇷🇺</Button>
      <Button className="lang-btn" onClick={()=> setLang('es')}>🇪🇸</Button>
      <Button className="lang-btn" onClick={()=> setLang('ar')}>🇦🇪</Button>
      {/* <span style={{ display: 'block', width: '40px', height: '30px', background: 'green'}}>ض</span> */}

      </div>

      <a className="bsclogo" href="http://buildingsafetycrisis.org" title="Visit the Official Building Safety Crisis website">
      <img height="100px" src="https://firebasestorage.googleapis.com/v0/b/claddingscandalmap.appspot.com/o/assets%2Fbsc-logo.png?alt=media&token=b681b31b-9d8b-4575-bcf0-ee522819639e" alt="Building Safety Crisis logo" />
      </a>

      

    </p>

  </div>
}

export default React.memo(Share)